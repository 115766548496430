export function matchCountryCode(phoneNumber, countryCodes = []) {
  const smsServicesAvailability = countryCodes;
  for (let i = 0; i < smsServicesAvailability.length; i++) {
    const countryCode = smsServicesAvailability[i];
    if (
      phoneNumber.startsWith(countryCode) ||
      phoneNumber.startsWith(countryCode.replace(/\s/g, ""))
    ) {
      return true;
    }
  }
  return false; // If no match found
}
