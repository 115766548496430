import React from "react";
import { createSvgIcon, useTheme } from "@mui/material";
export default function DownArrowIcon(props) {
  const { text } = useTheme();
  const color = text.secondary;
  const SvgIcon = createSvgIcon(
    <>
      <g id="Icon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.27748 9.27958C6.64745 8.90681 7.24729 8.90681 7.61726 9.27958L12 13.6955L16.3827 9.27958C16.7527 8.90681 17.3526 8.90681 17.7225 9.27958C18.0925 9.65235 18.0925 10.2567 17.7225 10.6295L12.6699 15.7204C12.2999 16.0932 11.7001 16.0932 11.3301 15.7204L6.27748 10.6295C5.90751 10.2567 5.90751 9.65235 6.27748 9.27958Z"
          fill={color}
        />
      </g>
    </>
  );
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
        ...props.sx,
      }}
      {...props}
    />
  );
}
