import heightIcon from "../assets/input-icons/height.svg";
import hipIcon from "../assets/input-icons/hip-female.svg";
import neckIcon from "../assets/input-icons/neck.svg";
import waistIcon from "../assets/input-icons/waist-female.svg";
import weightIcon from "../assets/input-icons/scale.svg";
import waistMale from "../assets/input-icons/waist-male.svg";
export const MeasurementsSourceMen = [
  {
    name: "height",
    label: "Height",
    icon: heightIcon,
  },

  {
    name: "weight",
    label: "Weight",
    icon: weightIcon,
  },

  {
    name: "waist",
    label: "Waist",
    icon: waistMale,
    description: "Narrowest part of abdomen",
  },

  {
    name: "neck",
    label: "Neck",
    icon: neckIcon,
    description: "Narrowest part",
  },
];

export const MeasurementsSourceWomen = [
  {
    name: "height",
    label: "Height",
    icon: heightIcon,
  },

  {
    name: "weight",
    label: "Weight",
    icon: weightIcon,
  },

  {
    name: "waist",
    label: "Waist",
    icon: waistIcon,
    description: "Narrowest part of abdomen",
  },

  {
    name: "hip",
    label: "Hip",
    icon: hipIcon,
    description: "Widest part in mirror",
    // iconSx: { backgroundSize: { xs: "100% 100%", sm: "100% 100%" } },
  },

  {
    name: "neck",
    label: "Neck",
    icon: neckIcon,
    description: "Narrowest part",
  },
];

export const MeasurementsSourceMenAfterLogin = [
  {
    name: "weight",
    label: "Weight",
    icon: weightIcon,
  },

  {
    name: "waist",
    label: "Waist",
    icon: waistMale,
    description: "Narrowest part of abdomen",
  },

  {
    name: "neck",
    label: "Neck",
    icon: neckIcon,
    description: "Narrowest part",
  },
];

export const MeasurementsSourceWomenAfterLogin = [
  {
    name: "weight",
    label: "Weight",
    icon: weightIcon,
  },

  {
    name: "waist",
    label: "Waist",
    icon: waistIcon,
    description: "Narrowest part of abdomen",
  },

  {
    name: "hip",
    label: "Hip",
    icon: hipIcon,
    description: "Widest part in mirror",
    // iconSx: { backgroundSize: { xs: "100% 100%", sm: "100% 100%" } },
  },

  {
    name: "neck",
    label: "Neck",
    icon: neckIcon,
    description: "Narrowest part",
  },
];
