import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import PrimaryButton from "../global/button/PrimaryButton";
import copy from "copy-to-clipboard";
import BtnToast from "../global/toast/BtnToast";
import { useAuth } from "../../hooks/store/useAuth";

const HealthyContent = () => {
  const theme = useTheme();
  const { email } = useAuth();
  const [showToast, setShowToast] = useState(false);

  const handleCopyLink = () => {
    copy(`${window.location.origin}?ref=${email}`);
    setShowToast(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px",
        background: theme.palette.success.secondary,
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "19.8px", sm: "22px" },
          color: theme.text.deepSuccess,
          fontWeight: theme.typography.headingSecondaryBold,
        }}
      >
        Congratulations!
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: "4px",
          lineHeight: "24px",
        }}
      >
        You are in the healthy range! 🎉
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: "4px",
          lineHeight: "24px",
        }}
      >
        Now shift your focus to maintaining the habits that got you here.
      </Typography>

      <Typography
        sx={{
          mt: "18px",
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingBasic,
          color: theme.text.basic,
          fontWeight: theme.typography.headingBasicBold,
        }}
      >
        Did this tracker help?
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: "4px",
          lineHeight: "24px",
        }}
      >
        If you know someone whose fat loss journey would be easier with this
        tracker, share it with them.
      </Typography>

      <Stack
        sx={{ mt: "20px", alignItems: "center", justifyContent: "center" }}
      >
        <PrimaryButton onClick={handleCopyLink}>Share tracker</PrimaryButton>
        <BtnToast
          text={"Link copied to clipboard"}
          visibility={showToast}
          setVisibility={setShowToast}
        />
      </Stack>
    </Box>
  );
};

export default HealthyContent;
