import * as yup from "yup";
import "yup-phone";

export const ReminderSchema = yup.object().shape(
  {
    number: yup
      .string()
      .nullable()
      .when("number", {
        is: (value) => value && value.length > 0,
        then: yup
          .string()
          .phone("Invalid phone number")
          .typeError("Phone number must be a valid number"),
        otherwise: yup.string(),
      }),
    repetitionInterval: yup
      .string()
      .oneOf(["Weekly", "Daily"], "Invalid repetition interval")
      .required("repetition interval is required"),
    dayOfWeek: yup
      .string()
      .oneOf(
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        "Invalid day of week"
      )
      .required("day of week is required"),
    time: yup
      .string()
      .typeError("Please enter a valid time")
      .required("Please enter a valid time"),
  },
  [["number", "number"]]
);

export const ReminderSettingsSchema = yup.object().shape({
  repetitionInterval: yup
    .string()
    .oneOf(["Weekly", "Daily"], "Invalid repetition interval")
    .required("Repetition interval is required"),
  dayOfWeek: yup.string().when("repetitionInterval", {
    is: (value) => value === "Weekly",
    then: yup
      .string()
      .oneOf(
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        "Invalid day of week"
      )
      .required("Day of week is required"),
    otherwise: yup.string().nullable(),
  }),
  time: yup
    .string()
    .typeError("Please enter a valid time")
    .required("Please enter a valid time"),
});
