import { Button, Collapse, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { formatFirebaseTimestamp } from "../../functions/formatFirebaseTimestamp";
import { useCommon } from "../../hooks/store/useCommon";
import { calculateDifference } from "../../utils/checkInCalculation";
import { performanceAdviser } from "../../utils/userPerformanceAdviser";

import TableRow from "./TableRow";
import AdviseContent from "./AdviseContent";
import { useAuth } from "../../hooks/store/useAuth";
import { measurementUnits } from "../../constants/measurementUnit";
import { lbToKg } from "../../functions/unitConvertor";
import daysBetween from "../../functions/daysBetween";
import HealthyContent from "./HealthyContent";
import { bodyStatusChecker } from "../../functions/bodyStatusChecker";
import * as amplitude from "@amplitude/analytics-browser";

const TableBody = ({ actualData, rowData, currentMonth }) => {
  const { unitPreference, sex } = useAuth();
  const calculatedRowData = calculateDifference(rowData);
  const { text } = useTheme();
  const {
    setOpenUpdateCheckInDialog,
    isProgressCheckIn,
    setIsProgressCheckIn,
  } = useCommon();
  return (
    <Collapse in={true}>
      <Stack sx={{ flexDirection: "column" }}>
        {calculatedRowData.map((record, index) => {
          const isOverFat =
            bodyStatusChecker(sex, "Fat Loss", record.accurateBF)?.status ===
            "Overfat";
          const advise = performanceAdviser(
            record.averageLeanMassDifference,
            record.averageFatMassDifference,
            record.averageBodyFatPercentageDifference,
            record.daysBetweenPreviousCheckIn,
            record.bodyWeightLossMoreThanOnePercentage
          );
          const isAdviceDataAvailable =
            daysBetween(rowData[rowData.length - 1]?.date, record?.date) >= 7
              ? true
              : false;

          // code for manage amplitude event(Progress_CheckIn) at new check-in by user after login

          if (isProgressCheckIn && index === 0) {
            setIsProgressCheckIn(false);
            if (isAdviceDataAvailable) {
              amplitude.logEvent("Progress_CheckIn", {
                count: rowData?.length,
                State: isOverFat ? advise?.status : "Healthy",
              });
            } else {
              amplitude.logEvent("Progress_CheckIn", {
                count: rowData?.length,
                State: "Need7DayGap",
              });
            }
          }
          return (
            formatFirebaseTimestamp(record?.date?.seconds, "MMM YYYY") ===
              currentMonth && (
              <Stack
                key={record.id}
                sx={{
                  display: { xs: "flex", md: "none" },
                  py: "24px",
                  gap: "8px",
                  paddingInline: "6px",
                }}
              >
                {/* record date and edit button */}
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        color: text.basic,
                        fontSize: { xs: "17.4px", sm: "18px" },
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                      }}
                    >
                      {formatFirebaseTimestamp(record?.date?.seconds, "MMMM D")}
                    </Typography>
                    {isAdviceDataAvailable && (
                      <Typography sx={{ fontSize: { xs: "12px", sm: "14px" } }}>
                        Since{" "}
                        {formatFirebaseTimestamp(
                          record?.compareToCheckIn?.date?.seconds,
                          "MMMM D, YYYY"
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Button
                    variant="text"
                    sx={{
                      p: 0,
                      fontWeight: 200,
                      color: "#5271FF",
                      fontSize: { xs: "14px", sm: "16px" },
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      return setOpenUpdateCheckInDialog(
                        true,
                        actualData[index].id,
                        actualData[index],
                        rowData?.length
                      );
                    }}
                  >
                    Edit
                  </Button>
                </Stack>

                <TableRow
                  hideComparisons={!isAdviceDataAvailable}
                  rowName={"Weight"}
                  difference={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.averageWeightDifference
                      : lbToKg(record.averageWeightDifference)
                  }
                  actualValue={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.weight
                      : lbToKg(record.weight)
                  }
                  valueIn={
                    unitPreference === measurementUnits.IMPERIAL ? " lb" : " kg"
                  }
                  adviseIndex={advise?.index}
                />

                <TableRow
                  hideComparisons={!isAdviceDataAvailable}
                  rowName={"Body Fat"}
                  difference={Number(
                    record.averageBodyFatPercentageDifference
                  ).toFixed(1)}
                  actualValue={record.accurateBF}
                  valueIn="%"
                  adviseIndex={advise?.index}
                />

                <TableRow
                  hideComparisons={!isAdviceDataAvailable}
                  rowName={"Fat Mass"}
                  difference={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.averageFatMassDifference
                      : lbToKg(record.averageFatMassDifference)
                  }
                  actualValue={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.fatMass
                      : lbToKg(record.fatMass)
                  }
                  valueIn={
                    unitPreference === measurementUnits.IMPERIAL ? " lb" : " kg"
                  }
                  adviseIndex={advise?.index}
                />

                <TableRow
                  hideComparisons={!isAdviceDataAvailable}
                  rowName={"Lean Mass"}
                  difference={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.averageLeanMassDifference
                      : lbToKg(record.averageLeanMassDifference)
                  }
                  actualValue={
                    unitPreference === measurementUnits.IMPERIAL
                      ? record.leanMass
                      : lbToKg(record.leanMass)
                  }
                  valueIn={
                    unitPreference === measurementUnits.IMPERIAL ? " lb" : " kg"
                  }
                  adviseIndex={advise?.index}
                />

                {isOverFat ? (
                  <AdviseContent
                    title={
                      isAdviceDataAvailable
                        ? advise.title
                        : "Waiting on more data"
                    }
                    description={
                      isAdviceDataAvailable
                        ? advise.description
                        : [
                            "We need ≥7 day gap between check-ins for meaningful analysis",
                          ]
                    }
                    hideAdditionalInfo={!isAdviceDataAvailable}
                  />
                ) : (
                  <HealthyContent />
                )}
              </Stack>
            )
          );
        })}
      </Stack>
    </Collapse>
  );
};

export default TableBody;
