import React from "react";
import { createSvgIcon } from "@mui/material";
export default function PlusIcon(props) {
  const SvgIcon = createSvgIcon(
    <>
      <g id="__id1867_s1qt142ful">
        <path
          d="M16,0C7.1776,0,0,7.1776,0,16s7.1776,16,16,16s16-7.1776,16-16S24.8224,0,16,0z M16,29.86667 C8.35365,29.86667,2.13333,23.64635,2.13333,16S8.35365,2.13333,16,2.13333S29.86667,8.35365,29.86667,16 S23.64635,29.86667,16,29.86667z"
          fill="rgb(250, 249, 249)"
        />
        <path
          d="M21.58073,14.93333h-4.51406v-4.51406c0-0.58906-0.47761-1.06667-1.06667-1.06667 c-0.58906,0-1.06667,0.4776-1.06667,1.06667v4.51406h-4.51406c-0.58906,0-1.06667,0.4776-1.06667,1.06667 c0,0.58906,0.4776,1.06667,1.06667,1.06667h4.51406v4.51406c0,0.58906,0.4776,1.06667,1.06667,1.06667 c0.58906,0,1.06667-0.4776,1.06667-1.06667v-4.51406h4.51406c0.58906,0,1.06667-0.47761,1.06667-1.06667 C22.6474,15.41094,22.16979,14.93333,21.58073,14.93333z"
          fill="rgb(250, 249, 249)"
        />
      </g>
    </>
  );
  return (
    <SvgIcon
      width="26"
      height="26"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          ...props.sx,
        },
      }}
      {...props}
    />
  );
}
