import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../services/firebase";

const useGetRealTimeData = (collectionName, documentId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (documentId) {
      const fetchData = async (collectionName, documentId) => {
        try {
          setLoading(true);
          setError(false);
          setErrorMessage("");

          const docRef = doc(db, collectionName, documentId);

          onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
              setResponse({ id: doc.id, ...doc.data() });
            } else {
              setResponse(null);
            }

            setLoading(false);
          });
        } catch (error) {
          setError(true);
          setErrorMessage(error.message);
          setLoading(false);
        }
      };

      fetchData(collectionName, documentId);
    }

    // Cleanup function
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, documentId]); // Include dependencies

  return { loading, error, errorMessage, response };
};

export default useGetRealTimeData;
