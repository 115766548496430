import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(tz);
dayjs.extend(utc);

export const repetitionInterval = [
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Daily",
    value: "Daily",
  },
];

export const dayOfWeek = [
  {
    label: "Su",
    value: "Sunday",
  },
  {
    label: "M",
    value: "Monday",
  },
  {
    label: "Tu",
    value: "Tuesday",
  },
  {
    label: "W",
    value: "Wednesday",
  },
  {
    label: "Th",
    value: "Thursday",
  },
  {
    label: "F",
    value: "Friday",
  },
  {
    label: "Sa",
    value: "Saturday",
  },
];

export const timeZones = [
  { value: "Etc/GMT+12", label: "GMT (International Date Line West) 🌐" },
  { value: "Pacific/Midway", label: "GMT-11:00 (Midway Island, Samoa) 🌐" },
  { value: "Pacific/Honolulu", label: "GMT-10:00 (Hawaii) 🌐" },
  { value: "America/Anchorage", label: "GMT-09:00 (Alaska) 🇺🇸" },
  { value: "America/Los_Angeles", label: "GMT-08:00 (Pacific Time) 🇺🇸" },
  { value: "America/Denver", label: "GMT-07:00 (Mountain Time) 🇺🇸" },
  { value: "America/Phoenix", label: "GMT-07:00 (Arizona) 🇺🇸" },
  { value: "America/Chicago", label: "GMT-06:00 (Central Time) 🇺🇸" },
  { value: "America/New_York", label: "GMT-05:00 (Eastern Time) 🇺🇸" },
  { value: "America/Caracas", label: "GMT-04:30 (Caracas) 🇻🇪" },
  { value: "America/Halifax", label: "GMT-04:00 (Atlantic Time) 🇨🇦" },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "GMT-03:00 (Buenos Aires) 🇦🇷",
  },
  { value: "America/Sao_Paulo", label: "GMT-03:00 (Brasilia) 🇧🇷" },
  { value: "Atlantic/South_Georgia", label: "GMT-02:00 (South Georgia) 🌐" },
  { value: "Atlantic/Azores", label: "GMT-01:00 (Azores) 🌐" },
  { value: "UTC", label: "GMT+00:00 (Coordinated Universal Time) 🌐" },
  { value: "Europe/London", label: "GMT+00:00 (London) 🇬🇧" },
  { value: "Europe/Berlin", label: "GMT+01:00 (Berlin) 🇩🇪" },
  { value: "Europe/Paris", label: "GMT+01:00 (Paris) 🇫🇷" },
  { value: "Europe/Lisbon", label: "GMT+01:00 (Lisbon, Portugal) 🇵🇹" },
  { value: "Africa/Casablanca", label: "GMT+00:00 (Casablanca) 🌐" },
  { value: "Africa/Algiers", label: "GMT+01:00 (Algiers) 🇩🇿" },
  { value: "Europe/Brussels", label: "GMT+01:00 (Brussels) 🇧🇪" },
  { value: "Europe/Amsterdam", label: "GMT+01:00 (Amsterdam) 🇳🇱" },
  { value: "Africa/Windhoek", label: "GMT+02:00 (Windhoek) 🇳🇦" },
  { value: "Asia/Beirut", label: "GMT+02:00 (Beirut) 🇱🇧" },
  { value: "Africa/Cairo", label: "GMT+02:00 (Cairo) 🇪🇬" },
  { value: "Asia/Jerusalem", label: "GMT+02:00 (Jerusalem) 🇮🇱" },
  { value: "Asia/Baghdad", label: "GMT+03:00 (Baghdad) 🇮🇶" },
  { value: "Asia/Riyadh", label: "GMT+03:00 (Riyadh) 🇸🇦" },
  { value: "Asia/Tehran", label: "GMT+03:30 (Tehran) 🇮🇷" },
  { value: "Asia/Dubai", label: "GMT+04:00 (Dubai) 🇦🇪" },
  { value: "Asia/Yerevan", label: "GMT+04:00 (Yerevan) 🇦🇲" },
  { value: "Asia/Kabul", label: "GMT+04:30 (Kabul) 🇦🇫" },
  { value: "Asia/Tashkent", label: "GMT+05:00 (Tashkent) 🇺🇿" },
  { value: "Asia/Calcutta", label: "GMT+05:30 (Calcutta) 🇮🇳" },
  { value: "Asia/Colombo", label: "GMT+05:30 (Colombo) 🇱🇰" },
  { value: "Asia/Kathmandu", label: "GMT+05:45 (Kathmandu) 🇳🇵" },
  { value: "Asia/Almaty", label: "GMT+06:00 (Almaty) 🇰🇿" },
  { value: "Asia/Dhaka", label: "GMT+06:00 (Dhaka) 🇧🇩" },
  { value: "Asia/Yekaterinburg", label: "GMT+05:00 (Yekaterinburg) 🇷🇺" },
  { value: "Asia/Rangoon", label: "GMT+06:30 (Yangon) 🇲🇲" },
  { value: "Asia/Bangkok", label: "GMT+07:00 (Bangkok) 🇹🇭" },
  { value: "Asia/Jakarta", label: "GMT+07:00 (Jakarta) 🇮🇩" },
  { value: "Asia/Krasnoyarsk", label: "GMT+08:00 (Krasnoyarsk) 🇷🇺" },
  { value: "Asia/Shanghai", label: "GMT+08:00 (Shanghai) 🇨🇳" },
  { value: "Asia/Irkutsk", label: "GMT+09:00 (Irkutsk) 🇷🇺" },
  { value: "Asia/Tokyo", label: "GMT+09:00 (Tokyo) 🇯🇵" },
  { value: "Australia/Darwin", label: "GMT+09:30 (Darwin) 🇦🇺" },
  { value: "Asia/Yakutsk", label: "GMT+10:00 (Yakutsk) 🇷🇺" },
  { value: "Australia/Sydney", label: "GMT+11:00 (Sydney) 🇦🇺" },
  { value: "Pacific/Noumea", label: "GMT+11:00 (Noumea) 🌐" },
  { value: "Pacific/Fiji", label: "GMT+12:00 (Fiji) 🇫🇯" },
  { value: "Pacific/Auckland", label: "GMT+13:00 (Auckland) 🇳🇿" },
  { value: "Asia/Manila", label: "GMT+08:00 (Philippine Time) 🇵🇭" },
];

// Get the local time zone
const localTimeZone = dayjs.tz.guess();

// Check if the local time zone already exists in the timeZones array
const timeZoneExists = timeZones.some((zone) => zone.value === localTimeZone);

// If the local time zone doesn't exist, add it to the timeZones array
if (!timeZoneExists) {
  timeZones.push({
    value: localTimeZone,
    label: `${dayjs().tz(localTimeZone).format("Z")} ${localTimeZone}`,
  });
}
