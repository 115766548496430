import React from "react";
import {
  Box,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import ElementLabel from "./ElementLabel";
import ErrorText from "./ErrorText";
export const NewRadioButton = ({
  control,
  name,
  label = false,
  options = [],
  description = false,
  onChangeOption = false,
  componentStyle = {},
  sx = {},
}) => {
  const theme = useTheme();

  const GenerateSingleOptions = () => {
    return options.map((option, index) => {
      return (
        <FormControlLabel
          key={index}
          value={option.value}
          sx={{
            m: 0,
            flex: 1,
            "& > span": {
              p: 0,
              width: "100%",
            },
          }}
          control={
            <Radio
              disableRipple
              icon={
                <Box
                  component={"span"}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "14px",
                    backgroundColor: "transparent",
                    color: "grey",
                    padding: { xs: "6px", sm: "10px" },
                    lineHeight: 1.5,
                    border: "1px solid",
                    textAlign: "center",
                    width: "100%",
                    borderLeftWidth: index === 0 ? "1px" : "0px",
                    borderRightWidth:
                      index === options.length - 1 ? "1px" : "0.5px",
                    borderTopLeftRadius: index === 0 ? "40px" : 0,
                    borderBottomLeftRadius: index === 0 ? "40px" : 0,
                    borderTopRightRadius:
                      index === options.length - 1 ? "40px" : 0,
                    borderBottomRightRadius:
                      index === options.length - 1 ? "40px" : 0,
                    ...sx,
                  }}
                >
                  {option.label}
                </Box>
              }
              checkedIcon={
                <Box
                  component={"span"}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: theme.palette.background.primary,
                    color: "white",
                    fontSize: "14px",
                    padding: { xs: "6px", sm: "10px" },
                    width: "100%",
                    lineHeight: 1.5,
                    textAlign: "center",
                    border: "1px solid transparent",
                    borderLeftWidth: index === 0 ? "1px" : "0px",
                    borderRightWidth:
                      index === options.length - 1 ? "1px" : "0.5px",
                    borderTopLeftRadius: index === 0 ? "40px" : 0,
                    borderBottomLeftRadius: index === 0 ? "40px" : 0,
                    borderTopRightRadius:
                      index === options.length - 1 ? "40px" : 0,
                    borderBottomRightRadius:
                      index === options.length - 1 ? "40px" : 0,
                    ...sx,
                  }}
                >
                  {option.label}
                </Box>
              }
            />
          }
        />
      );
    });
  };
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        ...componentStyle,
      }}
    >
      <ElementLabel label={label} description={description} />
      {/* select input */}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Stack
            sx={{
              mt: theme.margin.margin3,
            }}
          >
            <RadioGroup
              value={value}
              onChange={(e) => {
                onChange(e);
                // Invoke the callback with the selected value
                onChangeOption && onChangeOption(e.target.value);
              }}
              row
              aria-labelledby={name}
              name={name}
            >
              <GenerateSingleOptions />
            </RadioGroup>
            <Collapse in={Boolean(error)}>
              <ErrorText error={error} />
            </Collapse>
          </Stack>
        )}
      />

      {/* information text */}
    </Box>
  );
};
