import { create } from "zustand";

export const useFirstCheckIn = create((set) => ({
  goal: "",
  gender: "",
  dateOfBirth: null,
  height: "",
  weight: "",
  waist: "",
  neck: "",
  hip: "",
  unitPreference: "Imperial",

  //   1. select goal
  setGoal(goal) {
    set(() => ({
      goal,
    }));
  },

  //   2. basic details
  setBasicDetails(rest) {
    set((state) => ({ ...state, ...rest }));
  },

  //   3. measurement details
  setMeasurement(rest) {
    set((state) => ({
      ...state,
      ...rest,
    }));
  },

  //  4.set unit preferences
  setUnitPreference(rest) {
    set((state) => ({
      ...state,
      ...rest,
    }));
  },
}));
