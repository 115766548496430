import { Box, Typography, alpha, useTheme } from "@mui/material";
import React, { useState } from "react";
import SkinnyFatFemale from "../../assets/SkinnyFatFemale.webp";
import SkinnyFatMale from "../../assets/SkinnyFatMale.webp";
import AppIcon from "../../icons/appView.svg";
import MailForm from "../global/form/MailForm";
import PrimaryButton from "../global/button/PrimaryButton";
import copy from "copy-to-clipboard";
import BtnToast from "../global/toast/BtnToast";

const FatLossContent = ({ bodyStatus, gender, goal }) => {
  const theme = useTheme();
  const [showToast, setShowToast] = useState(false);

  const handleCopyLink = () => {
    copy(window.location.origin);
    setShowToast(true);
  };

  return (
    <Box
      sx={{
        marginTop: theme.margin.margin1,
        padding: theme.padding.padding2,
        background: alpha(theme.palette.background.primary, 0.1),
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "24px", sm: "28px" },
          lineHeight: { xs: "24px", sm: "28px" },
          color: theme.text.primary,
          fontWeight: theme.typography.headingMainBold,
        }}
      >
        {bodyStatus.index === 1
          ? "Know someone who is losing weight?"
          : "What can I do?"}
      </Typography>
      <Box
        component={"ol"}
        sx={{
          listStylePosition: "outside",
          marginLeft: { xs: "14px", sm: "18px" },
        }}
      >
        <Typography
          component={"li"}
          sx={{
            mt: theme.margin.margin3,
            listStyleType: "i",
            fontFamily: theme.typography.fontFamily2,
            fontSize: { xs: "18px", sm: "22px" },
            color: theme.text.basic,
            fontWeight: 600,
            lineHeight: { xs: "20px", sm: "24px" },
          }}
        >
          {bodyStatus.index === 1 ? (
            <>
              Help them focus on <u>fat</u> loss (not weight loss)
            </>
          ) : (
            <>
              Focus on <u>fat</u> loss (not weight loss)
            </>
          )}
        </Typography>
      </Box>
      <Box
        src={gender === "Male" ? SkinnyFatMale : SkinnyFatFemale}
        component={"img"}
        sx={{ width: "100%", marginTop: theme.margin.margin2 }}
      ></Box>
      <Box
        component={"ol"}
        start={"2"}
        sx={{
          listStylePosition: "outside",
          marginLeft: { xs: "18px", sm: "22px" },
        }}
      >
        <Typography
          component={"li"}
          sx={{
            marginTop: theme.margin.margin2,
            listStyleType: "i",
            fontFamily: theme.typography.fontFamily2,
            fontSize: { xs: "18px", sm: "20px" },
            color: theme.text.basic,
            fontWeight: 600,
            lineHeight: { xs: "20px", sm: "22px" },
          }}
        >
          {bodyStatus.index === 1
            ? "Help them avoid the skinny fat fate"
            : "Avoid the skinny fat fate"}
        </Typography>
      </Box>

      {bodyStatus.index !== 1 ? (
        <Box
          src={AppIcon}
          component={"img"}
          sx={{ width: "100%", marginTop: theme.margin.margin2 }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <PrimaryButton
            onClick={handleCopyLink}
            type="submit"
            sx={{
              marginTop: theme.margin.margin1,
              width: { xs: "100%", md: "unset" },
              maxWidth: "240px !important",
              alignSelf: "center",
            }}
          >
            Share this calculator
          </PrimaryButton>
          <BtnToast
            text={"Link copied to clipboard"}
            visibility={showToast}
            setVisibility={setShowToast}
          />
        </Box>
      )}

      {(bodyStatus.index === 2 || bodyStatus.index === 3) && <MailForm />}
    </Box>
  );
};

export default FatLossContent;
