import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useGuest = create(
  persist(
    (set) => ({
      guestUserId: undefined,
      guestCheckIns: [],
      currentCheckInId: undefined,
      guestUnitPreference: undefined,

      setGuestUserId: (guestUserId) => {
        set((state) => ({
          ...state,
          guestUserId,
        }));
      },

      setGuestCheckIns: (id) => {
        set((state) => ({
          ...state,
          guestCheckIns: [...state.guestCheckIns, id],
        }));
      },

      setCurrentCheckInId: (id = undefined) => {
        set((state) => ({
          ...state,
          currentCheckInId: id,
        }));
      },

      setGuestUnitPreference: (unitData) => {
        set((state) => ({
          ...state,
          guestUnitPreference: unitData,
        }));
      },
    }),
    { name: "guest" }
  )
);
