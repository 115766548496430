import { Button, alpha, useTheme } from "@mui/material";
import React from "react";

const OutlinedButton = ({
  children,
  isLoading = false,
  disabled = false,
  sx,
  ...rest
}) => {
  const { button } = useTheme();
  return (
    <Button
      disabled={isLoading || disabled}
      disableRipple
      sx={{
        textTransform: "unset",

        // css for disable button
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "no-drop",
          borderColor: isLoading
            ? `${button.outlined.color} !important`
            : `${button.outlined.disabled} !important`,
          color: `${button.outlined.textDisabled}!important`,
        },

        // css for hover button
        "&.MuiButton-root:hover": {
          backgroundColor:
            !isLoading && !disabled
              ? `${alpha(button.outlined.color, 0.1)} !important`
              : "transparent !important",
        },

        "&.MuiButton-root:active": {
          backgroundColor: !isLoading && alpha(button.outlined.color, 0.2),
        },

        // css for loading animation on is loading true
        "&.MuiButton-root": {
          border: "2px solid",
          borderColor: button.outlined.color,
          color: button.outlined.textOutlined,
          minWidth: "unset",
          padding: { xs: "13px", sm: "15px" },
          borderRadius: "14px",
          boxShadow: "none",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "18px",
        },
        ".bouncing-loader": {
          display: "flex",
          padding: "0px 8px",
          alignItems: "center",
          gap: "18px",
        },
        ".bouncing-loader > div": {
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          backgroundColor: button.outlined.textOutlined,
          opacity: 1,
          animation: "bouncing-loader 0.6s infinite alternate",
        },
        "@keyframes bouncing-loader": {
          to: {
            opacity: 0.1,
            //transform: "translateY(-2px)"
          },
        },
        ".bouncing-loader > div:nth-of-type(2)": { animationDelay: "0.2s" },
        ".bouncing-loader > div:nth-of-type(3)": { animationDelay: "0.4s" },
        ...sx,
      }}
      {...rest}
    >
      {isLoading ? (
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default OutlinedButton;
