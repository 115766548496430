import * as amplitude from "@amplitude/analytics-browser";
import { Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { inchesToCm, lbToKg } from "../../../functions/unitConvertor";
import { formatFirebaseTimestamp } from "../../../functions/formatFirebaseTimestamp";
import { useAuth } from "../../../hooks/store/useAuth";
import useFindData from "../../../hooks/useFindData";
import OutlinedButton from "../../global/button/OutlinedButton";
import BtnToast from "../../global/toast/BtnToast";
import useApiCall from "../../../hooks/useApiCall";

const ExportData = () => {
  const theme = useTheme();
  const { userId, sex, unitPreference, email } = useAuth();
  const { findData, loading } = useFindData();
  const { apiCall } = useApiCall();
  const [toastVisibility, setToastVisibility] = useState(false);

  async function exportCheckInData() {
    let data = await findData(
      "/checkIns",
      "userId",
      "==",
      userId,
      "createdAt",
      "desc"
    );
    const tableHead =
      sex === "Male"
        ? unitPreference === "Imperial"
          ? [
              "date",
              "height(inch)",
              "weight(pound)",
              "waist(inch)",
              "neck(inch)",
            ]
          : ["date", "height(kg)", "weight(cm)", "waist(cm)", "neck(cm)"]
        : unitPreference === "Imperial"
        ? [
            "date",
            "height(pound)",
            "weight(inch)",
            "waist(inch)",
            "neck(inch",
            "hip(inch)",
          ]
        : [
            "date",
            "height(cm)",
            "weight(kg)",
            "waist(cm)",
            "neck(cm)",
            "hip(cm)",
          ];

    const keys =
      sex === "Male"
        ? ["date", "height", "weight", "waist", "neck"]
        : ["date", "height", "weight", "waist", "neck", "hip"];

    const header = tableHead.join(",");
    const rows = data.map((obj) =>
      keys
        .map((key) => {
          if (key === "date") {
          }
          switch (key) {
            case "date":
              return `"${
                formatFirebaseTimestamp(obj["createdAt"].seconds) || ""
              }"`;
            case "height":
              return `"${
                unitPreference === "Imperial"
                  ? obj["height"]
                  : inchesToCm(obj["height"])
              }"`;
            case "waist":
              return `"${
                unitPreference === "Imperial"
                  ? obj["waist"]
                  : inchesToCm(obj["waist"])
              }"`;
            case "neck":
              return `"${
                unitPreference === "Imperial"
                  ? obj["neck"]
                  : inchesToCm(obj["neck"])
              }"`;
            case "hip":
              return `"${
                unitPreference === "Imperial"
                  ? obj["hip"]
                  : inchesToCm(obj["hip"])
              }"`;
            case "weight":
              return `"${
                unitPreference === "Imperial"
                  ? obj["weight"]
                  : lbToKg(obj["weight"])
              }"`;
            default:
          }
          return `"${obj[key] || ""}"`;
        })
        .join(",")
    );
    let csvData = `${header}\n${rows.join("\n")}`;

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a download link
    const link = document.createElement("a");
    link.style.visibility = "hidden";
    link.href = URL.createObjectURL(blob);
    link.download = "userData.csv";

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);

    // send checkIns record csv to mail
    apiCall("post", "/exportCheckInUserData", {
      body: { userId },
    });

    amplitude.logEvent("Privacy_ExportedData");
    return setToastVisibility(true);
  }
  return (
    <Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: theme.typography.heading1,
            fontWeight: theme.typography.heading1Bold,
          }}
        >
          Export my data
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.heading1,
          }}
        >
          We’ll email it to{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: theme.typography.heading1,
              color: theme.text.primary,
              fontWeight: 600,
              lineBreak: "anywhere",
            }}
          >
            {email}
          </Typography>
        </Typography>
      </Stack>
      <OutlinedButton
        onClick={exportCheckInData}
        isLoading={loading}
        sx={{
          mt: { xs: "12px", sm: "16px" },
          width: "100%",
          maxWidth: "212px",
          alignSelf: { xs: "center", sm: "unset" },
        }}
      >
        Export My Data
      </OutlinedButton>
      <BtnToast
        text={"Check your email."}
        visibility={toastVisibility}
        setVisibility={setToastVisibility}
        stackSx={{ justifyContent: { xs: "center", sm: "left" } }}
      />
    </Stack>
  );
};

export default ExportData;
