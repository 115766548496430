import { ClickAwayListener } from "@mui/base";
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import WarningIcon from "../../icons/form-icon/WarningIcon";

const ToolTip = ({ children }) => {
  const { boxShadow, text } = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                p: 0,
                boxShadow: boxShadow.basic,
                backgroundColor: "background.main",
                maxWidth: "unset",
              },
            },
          }}
          PopperProps={{
            disablePortal: true,
            sx: {
              "& .MuiTooltip-arrow": {
                fontSize: "16px",
              },
              "& .MuiTooltip-arrow::before": {
                boxShadow: boxShadow.basic,
                backgroundColor: "background.main",
              },
              "&.MuiTooltip-popper": {
                width: { xs: "180px", sm: "280px" },
              },
            },
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Typography sx={{ fontSize: "14px", p: "6px", color: text.basic }}>
              {children}
            </Typography>
          }
          sx={{
            "& .MuiTooltip-tooltip": {
              backgroundColor: "background.main !important",
            },
          }}
        >
          <IconButton sx={{ p: 0 }} onClick={handleTooltipOpen}>
            <WarningIcon color={text.primary} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ToolTip;
