import {
  findDayDifferenceBetweenDate,
  formatFirebaseTimestamp,
} from "../functions/formatFirebaseTimestamp";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

function calculateDifference(rowData, matchTodays = 7) {
  const resultArray = [];

  for (let i = 0; i < rowData.length; i++) {
    const currentRecord = rowData[i]; // First record in the subset

    // Convert Firebase timestamp to normal date
    const currentRecordDate = new Date(currentRecord.date.seconds * 1000);

    // Calculate the start date for matching (previous matchTodays days from the current record's date)
    const matchRecordDate = new Date(currentRecordDate);
    matchRecordDate.setDate(matchRecordDate.getDate() - matchTodays);

    // Find the index of the last record within the matching range
    let lastIndex = i;
    for (let j = i + 1; j < rowData.length; j++) {
      const recordDate = new Date(rowData[j].date.seconds * 1000);
      if (dayjs(matchRecordDate).isSameOrAfter(recordDate)) {
        lastIndex = j;
        break;
      }
    }

    let compareToCheckIn = rowData[lastIndex];

    const daysBetweenPreviousCheckIn = findDayDifferenceBetweenDate(
      currentRecord?.date?.seconds,
      compareToCheckIn?.date?.seconds
    );

    // calculate is body weight loss in more then one percent or not from previous check in
    const compareRecordWeightAfterCutOnePercentage =
      compareToCheckIn?.weight - currentRecord?.weight * 0.01;
    const bodyWeightLossMoreThanOnePercentage =
      currentRecord?.weight <= compareRecordWeightAfterCutOnePercentage;

    // Comparing with the first record
    const comparisonResult = {
      daysBetweenPreviousCheckIn,
      averageWeightDifference: isNaN(
        currentRecord.weight - compareToCheckIn.weight
      )
        ? 0
        : currentRecord.weight - compareToCheckIn.weight,
      averageFatMassDifference: isNaN(
        currentRecord.fatMass - compareToCheckIn.fatMass
      )
        ? 0
        : currentRecord.fatMass - compareToCheckIn.fatMass,
      averageLeanMassDifference: isNaN(
        currentRecord.leanMass - compareToCheckIn.leanMass
      )
        ? 0
        : currentRecord.leanMass - compareToCheckIn.leanMass,
      averageBodyFatPercentageDifference: isNaN(
        currentRecord.accurateBF - compareToCheckIn.accurateBF
      )
        ? 0
        : currentRecord.accurateBF - compareToCheckIn.accurateBF,
    };
    resultArray.push({
      ...comparisonResult,
      ...currentRecord,
      compareToCheckIn,
      currentRecordDate,
      matchRecordDate,
      bodyWeightLossMoreThanOnePercentage,
      recordDate: formatFirebaseTimestamp(currentRecord?.date?.seconds),
    });
  }

  return resultArray;
}

export { calculateDifference };
