import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import OnBoarding from "./pages/OnBoarding";
import ReminderForm from "./components/home/ReminderForm";
import MyProgress from "./pages/MyProgress";
import Login from "./pages/Login";
import VerifyUser from "./pages/VerifyUser";
import { useAuth } from "./hooks/store/useAuth";
import Settings from "./pages/Settings";
import TapeReminder from "./pages/TapeReminder";
import UpdateEmailPhone from "./components/settings/forms/UpdateEmailPhone";
import GoalForm from "./components/home/GoalForm";
import Result from "./components/home/Result";
import Measurement from "./pages/Measurement";
import Help from "./pages/Help";
import FinalStep from "./components/home/FinalStep";
import UnSubscribeMail from "./pages/UnSubscribeMail";
import ShortUrl from "./pages/ShortUrl";
import { Helmet } from "react-helmet";

function LoggedInProtectedRoute({ children }) {
  let { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/" />;
}
function WithOutLoggedInProtectedRoute({ children }) {
  let { isLoggedIn } = useAuth();
  return !isLoggedIn ? children : <Navigate to="/myprogress" />;
}

const getRoutesForHostname = (hostname) => {
  if (hostname === process.env.REACT_APP_SHORT_URL_DOMAIN) {
    return [
      {
        path: "/:token",
        element: <ShortUrl />,
      },
      {
        path: "*",
        element: <div>page not found!</div>,
      },
    ];
  } else {
    return [
      {
        path: "/",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: Set Goals</title>
            </Helmet>
            <GoalForm />
          </WithOutLoggedInProtectedRoute>
        ),
      },
      {
        path: "/measurement",
        element: (
          <>
            <Helmet>
              <title>Body Fat Tracker: Input Measurements</title>
            </Helmet>
            <Measurement />
          </>
        ),
      },

      {
        path: "/result",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: Your BF%</title>
            </Helmet>
            <Result />
          </WithOutLoggedInProtectedRoute>
        ),
      },

      {
        path: "/final-step",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: Sign-up</title>
            </Helmet>
            <FinalStep />
          </WithOutLoggedInProtectedRoute>
        ),
      },

      {
        path: "/onboarding",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: You’re almost signed up</title>
            </Helmet>
            <OnBoarding />
          </WithOutLoggedInProtectedRoute>
        ),
      },
      {
        path: "/set-reminder",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: Set Reminders</title>
            </Helmet>
            <ReminderForm />
          </WithOutLoggedInProtectedRoute>
        ),
      },
      {
        path: "/myprogress",
        element: (
          <LoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: My Progress</title>
            </Helmet>
            <MyProgress />
          </LoggedInProtectedRoute>
        ),
      },
      {
        path: "/settings/notification",
        element: (
          <LoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: My Notifications</title>
            </Helmet>
            <Settings />
          </LoggedInProtectedRoute>
        ),
      },
      {
        path: "/settings/privacy",
        element: (
          <LoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: My Privacy</title>
            </Helmet>
            <Settings />
          </LoggedInProtectedRoute>
        ),
      },
      {
        path: "/profile",
        element: (
          <LoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: My Profile</title>
            </Helmet>
            <UpdateEmailPhone />
          </LoggedInProtectedRoute>
        ),
      },
      {
        path: "/login",
        element: (
          <WithOutLoggedInProtectedRoute>
            <Login />
          </WithOutLoggedInProtectedRoute>
        ),
      },
      {
        path: "/verify",
        element: <VerifyUser />,
      },
      {
        path: "/tape-reminder",
        element: (
          <WithOutLoggedInProtectedRoute>
            <TapeReminder />
          </WithOutLoggedInProtectedRoute>
        ),
      },
      {
        path: "/tech-support",
        element: (
          <>
            <Helmet>
              <title>Profile - Body Fat Tracker: Tech Support</title>
            </Helmet>
            <Help />
          </>
        ),
      },
      {
        path: "/coaching",
        element: (
          <LoggedInProtectedRoute>
            <Helmet>
              <title>Body Fat Tracker: Get Coaching</title>
            </Helmet>
            <Help />
          </LoggedInProtectedRoute>
        ),
      },
      {
        path: "/unsubscribe",
        element: <UnSubscribeMail />,
      },
      {
        path: "*",
        element: <div>page not found!</div>,
      },
    ];
  }
};

const hostname = window.location.hostname;
const routes = getRoutesForHostname(hostname);
export const routerConfig = createBrowserRouter(routes);
