import * as amplitude from "@amplitude/analytics-browser";
import { Box, Stack, Typography, alpha, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";
import { calculateBodyComposition } from "../../functions/bodyFatCalc";
import { cmToInches, kgToLb } from "../../functions/unitConvertor";
import useSetData from "../../hooks/useSetData";
import { generateUniqueId } from "../../functions/generateUniqueId";
import useUpdateData from "../../hooks/useUpdateData";
import { useGuest } from "../../hooks/store/useGuest";
import { bodyStatusChecker } from "../../functions/bodyStatusChecker";
// import VIdeoWithTitle from "../result/VIdeoWithTitle";
import StatusBar from "../result/StatusBar";
import FatLossContent from "../result/FatLossContent";
import { Link, useNavigate } from "react-router-dom";
import MainContainer from "../global/container/MainContainer";
import Breadcrumb from "./Breadcrumb";
import { useAuth } from "../../hooks/store/useAuth";
import PrimaryButton from "../global/button/PrimaryButton";
import { healthStatus } from "../../constants/healthStatus";
import { genders } from "../../constants/gender";
import { measurementUnits } from "../../constants/measurementUnit";
import { useCommon } from "../../hooks/store/useCommon";

const Result = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { setData } = useSetData();
  const { updateData } = useUpdateData();
  const { fromTrigger } = useCommon();
  const theme = useTheme();
  const { currentCheckInId, setCurrentCheckInId, setGuestCheckIns } =
    useGuest();
  const {
    goal,
    gender,
    dateOfBirth,
    height,
    weight,
    waist,
    neck,
    hip,
    unitPreference,
  } = useFirstCheckIn();

  const [calcResult, setCalcResult] = useState(false);
  const [bodyStatus, setBodyStatus] = useState(false);
  const storeGuestData = useCallback(async function (data) {
    try {
      if (currentCheckInId) {
        // update guest check in record inside firebase
        await updateData("/checkIns", data, currentCheckInId);
        return 0;
      } else {
        // create new guest user check in record
        const recordId = generateUniqueId();
        await setData("/checkIns", data, recordId);
        setCurrentCheckInId(recordId);
        setGuestCheckIns(recordId);
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (height === "" || isLoggedIn) {
      return navigate(isLoggedIn ? "/myprogress" : "/");
    }

    const data = {
      weight:
        unitPreference === measurementUnits.IMPERIAL ? weight : kgToLb(weight), // in lbs
      waist:
        unitPreference === measurementUnits.IMPERIAL
          ? waist
          : cmToInches(waist), // in inches
      neck:
        unitPreference === measurementUnits.IMPERIAL ? neck : cmToInches(neck), // in inches
      height:
        unitPreference === measurementUnits.IMPERIAL
          ? height
          : cmToInches(height), // in inches
      gender: gender,
      ...(gender === genders.FEMALE
        ? {
            hip:
              unitPreference === measurementUnits.IMPERIAL
                ? hip
                : cmToInches(hip),
          }
        : {}),
      dateOfBirth: dateOfBirth,
      goal: goal,
    };
    const result = calculateBodyComposition(
      data.weight,
      data.waist,
      data.neck,
      data.height,
      data.gender,
      data.hip,
      data.goal
    );
    const bfResult = bodyStatusChecker(gender, goal, result.bodyFatPercentage);
    setBodyStatus(bfResult);

    // set the body stage property to user object of amplitude
    const identifyObj = new amplitude.Identify();
    identifyObj.set(
      "Stage",
      bfResult.status === healthStatus.HEALTHY ? "Good" : bfResult.status
    );

    amplitude.identify(identifyObj);

    // log event
    amplitude.logEvent("Result_Visited", {
      Stage:
        bfResult.status === healthStatus.HEALTHY ? "Good" : bfResult.status,
      Trigger: fromTrigger ? fromTrigger : undefined,
    });

    setCalcResult(result);
    storeGuestData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unitPreference,
    storeGuestData,
    gender,
    goal,
    height,
    hip,
    neck,
    waist,
    weight,
    dateOfBirth,
  ]);
  return (
    <MainContainer>
      <Breadcrumb hideFinalStep={[0, 1].includes(Number(bodyStatus.index))} />
      <Stack>
        <Stack
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr" },
            gap: "24px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.main",
            }}
          >
            <StatusBar result={calcResult} bodyStatus={bodyStatus} />

            {Number(bodyStatus.index) === 0 && (
              <Box
                sx={{
                  marginTop: theme.margin.margin1,
                  padding: theme.padding.padding2,
                  background: alpha(theme.palette.background.primary, 0.1),
                  borderRadius: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily2,
                    fontSize: theme.typography.headingMain,
                    color: theme.text.primary,
                    fontWeight: theme.typography.headingMainBold,
                  }}
                >
                  Gain weight
                </Typography>
                <Typography
                  sx={{
                    color: theme.text.basic,
                    fontSize: theme.typography.heading1,
                    marginTop: theme.margin.margin3,
                  }}
                >
                  But in a way where you’re gaining fat and lean mass in similar
                  ratios, so you don’t end up in the Unhealthy range.
                </Typography>
                <Typography
                  sx={{
                    color: theme.text.basic,
                    fontSize: theme.typography.heading1,
                    marginTop: theme.margin.margin3,
                  }}
                >
                  Resources that may be helpful:
                </Typography>

                <Box
                  component={"ul"}
                  sx={{
                    listStylePosition: "outside",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    component={"li"}
                    sx={{
                      color: theme.text.basic,
                      fontSize: theme.typography.heading1,
                      marginTop: theme.margin.margin3,
                    }}
                  >
                    <a
                      target="_blank"
                      href="https://www.nhs.uk/live-well/healthy-weight/managing-your-weight/healthy-ways-to-gain-weight/"
                      rel="noreferrer"
                    >
                      National Health Service
                    </a>{" "}
                    to ground you on why this is important and how to filter
                    extreme advice (avoid their “do nots“)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: theme.text.basic,
                      fontSize: theme.typography.heading1,
                      marginTop: theme.margin.margin3,
                    }}
                  >
                    <a
                      target="_blank"
                      href="https://www.nerdfitness.com/blog/the-beginners-guide-to-building-muscle-and-strength/"
                      rel="noreferrer"
                    >
                      Nerd Fitness Guide
                    </a>{" "}
                    on how to build muscle while gaining weight, so you don‘t
                    end up in the Unhealthy range.
                  </Typography>
                </Box>
              </Box>
            )}

            {Number(bodyStatus.index) === 1 && (
              <Box
                sx={{
                  marginTop: theme.margin.margin1,
                  padding: theme.padding.padding2,
                  backgroundColor: theme.palette.success.secondary,
                  borderRadius: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily2,
                    fontSize: theme.typography.headingMain,
                    color: theme.palette.success.dark,
                    fontWeight: theme.typography.headingMainBold,
                  }}
                >
                  Congrats!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.typography.headingSecondary,
                    fontSize: "18px",
                    color: theme.text.basic,
                    fontWeight: theme.typography.heading2Bold,
                  }}
                >
                  What you’re doing is working. Keep doing it!
                </Typography>
              </Box>
            )}

            {[1].includes(Number(bodyStatus.index)) && (
              <>
                <FatLossContent
                  bodyStatus={bodyStatus}
                  gender={gender}
                  goal={goal}
                />
              </>
            )}

            {[2, 3].includes(Number(bodyStatus.index)) && (
              <Box
                sx={{
                  mt: "24px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  component={Link}
                  to={"/final-step"}
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "18px !important",
                      fontSize: "18px",
                      lineHeight: "24px",
                      padding: "15px !important",
                    },
                  }}
                >
                  Final Step
                </PrimaryButton>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </MainContainer>
  );
};

export default Result;
