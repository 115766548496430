import * as yup from "yup";
import dayjs from "dayjs";
export const BasicDetailsSchema = yup.object().shape({
  goal: yup.string().required("Please select a goal"),
  gender: yup.string().required("Please select a gender"),
  dateOfBirth: yup
    .date("Date of Birth is required")
    .typeError("Please select a valid Date of Birth.")
    .required("Date of Birth is required")
    .test({
      name: "dateValidation",
      message: "Sorry, you must be 18 years or older",
      test: (value) => {
        const minDate = dayjs().subtract(18, "year");
        return dayjs(value).isValid() && dayjs(value).isBefore(minDate);
      },
    }),
});
