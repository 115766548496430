import {
  Collapse,
  Stack,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
  alpha,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export const InputTextArea = ({
  name,
  label = "",
  type = "text",
  placeholder = "",
  control,
  sx,
  componentStyle = {},
  errorMsg = true,
  errorSx = {},
  minRows = 2,
}) => {
  const theme = useTheme();
  return (
    <Stack sx={{ width: "100%", mt: "28px", ...componentStyle }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  color: theme.text.basic,
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: theme.typography.heading1Bold,
                  textWrap: "nowrap",
                  whiteSpace: "pre",
                  lineHeight: "16px",
                }}
              >
                {label}
              </Typography>
              <Textarea
                placeholder={placeholder}
                aria-label="minimum height"
                minRows={minRows}
                type={type}
                onChange={onChange}
                value={value}
                sx={{
                  resize: "none",
                  width: "100%",
                  maxWidth: "502px",
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: 500,
                  borderRadius: "18px",
                  border: "2px solid",
                  borderColor: "input.border",
                  fontSize: { xs: "14px", sm: "16px" },
                  "&::placeholder": {
                    fontFamily: theme.typography.fontFamily,
                    color: alpha(theme.text.basic, 0.4),
                    fontWeight: 400,
                  },
                  ...sx,
                }}
              />
            </Stack>
            <Collapse in={Boolean(error) && Boolean(errorMsg)}>
              <ErrorText error={error} errorSx={errorSx} />
            </Collapse>
          </Stack>
        )}
      />
    </Stack>
  );
};
