import React from "react";
import { createSvgIcon } from "@mui/material";
export default function LogoIcon(props) {
  const SvgIcon = createSvgIcon(
    <>
      <g>
        <path fill="#FEFEFC" d="M0 0h40v40H0V0zm0 0"></path>
        <path
          fill="#F86802"
          d="M0 0h40v40H0V0zm13.2 1.2c-.24.09-.477.183-.723.277-.844.34-1.665.71-2.477 1.125V3c-.242.055-.242.055-.488.113C6.742 4.23 4.004 7.305 2.8 10v.398h-.403C.008 15.652-.73 21.387 1.238 26.926c.586 1.433 1.364 2.75 2.16 4.074l.325.555c.277.441.586.773.965 1.133.398.398.757.832 1.125 1.261 3.058 3.399 7.738 5.606 12.292 5.883 5.395.258 10.555-1.008 14.696-4.633.336-.328.668-.664 1-1 .16-.156.32-.312.488-.472 1.148-1.024 1.148-1.024 1.711-2.329h.398c1.301-2.257 2.5-4.421 3-7 .036-.136.07-.277.102-.421.324-1.418.367-2.79.363-4.239.004-.226.004-.453.008-.687.004-2.258-.473-4.344-1.297-6.438-.058-.152-.117-.3-.176-.457A17.082 17.082 0 0036 8h-.398c-.047-.14-.09-.281-.137-.43-.324-.699-.781-1.175-1.328-1.707-.094-.097-.188-.195-.285-.293C33.277 5 32.773 4.64 32 4.398V4c-.344-.273-.344-.273-.773-.523l-.438-.274C30.414 2.97 30.414 2.97 30 3v-.398c-1.273-.758-2.613-1.227-4.023-1.653-.172-.05-.34-.101-.516-.156-3.898-1.14-8.469-1.055-12.262.406zm0 0"
        ></path>
        <path
          fill="#E97421"
          d="M24.602 10.398c.785-.093 1.28-.011 1.937.415.914.718 1.195 1.558 1.371 2.695.57 4.98-.277 9.789-2.91 14.094-.074.125-.152.25-.23.378-.72 1.102-1.707 2-2.97 2.418-1.034.114-1.902.2-2.788-.386-1.07-1.59-1.34-2.918-1.301-4.825.004-.226.004-.453.008-.687.031-1.727.218-3.406.465-5.11.515-3.624.515-3.624.015-7.19-.719-.06-.719-.06-1.355.238-1.367 1.55-2.508 3.699-2.57 5.8.066.797.132 1.387.726 1.961a11.6 11.6 0 001.8.602c-.116.574-.171.773-.6 1.199-.868.133-1.782.184-2.598-.176-.801-.605-1.043-1.262-1.204-2.222-.242-2.633.793-5.114 2.446-7.145 1.035-1.09 1.883-1.719 3.383-1.957.793.137 1.07.484 1.574 1.102 1.66 2.574.55 7.543.215 10.375-.164 1.406-.278 2.793-.278 4.21 0 .153-.004.301-.004.458.012.804.11 1.433.465 2.156.649.078 1.176.12 1.762-.2 1.95-1.546 2.637-3.89 3.238-6.203.098-.367.098-.367.196-.746.687-3.093.855-6.629-.196-9.652-.07-.266-.137-.531-.199-.8h-.398v-.802zm0 0"
        ></path>
        <path
          fill="#FBFDF9"
          d="M17.762 11.938c.144.019.289.042.437.062.555.957.469 1.871.465 2.95 0 .214-.004.425-.004.648a32.877 32.877 0 01-.36 4.379 39.394 39.394 0 00-.386 5.21c-.008.215-.012.43-.016.649.004 1.371.247 2.363.903 3.562.133.27.265.536.398.801-.328-.066-.66-.133-1-.199v-1h-.398c-.39-1.46-.45-2.805-.426-4.313 0-.222.004-.44.004-.667.008-.54.012-1.079.02-1.622-.196.008-.395.012-.598.016a1134.002 1134.002 0 01-1.551.023c-.648-.039-.648-.039-1.05-.437.136-.004.277-.004.417-.008.543-.031 1.051-.07 1.582-.191.446-.477.446-.477.602-1-.25-.016-.496-.035-.75-.051-.621-.078-.828-.133-1.34-.523-.707-.965-.617-2.086-.512-3.227.422-1.59 1.7-4.855 3.563-5.063zm0 0"
        ></path>
        <path
          fill="#F56908"
          d="M18.8 11.602c.395.226.395.226.802.597.078.54.078.54.046 1.137-.003.2-.011.398-.02.605-.015.227-.015.227-.026.457H20c.559 1.918.117 4.024-.14 5.961-.27 2.028-.497 4.059-.536 6.102-.004.14-.008.277-.015.418-.004.992.23 1.582.89 2.32.512.188.512.188 1.051.176l.54.023h.41v.403c-.934.62-1.907.594-3 .398-.848-.629-1.11-1.62-1.4-2.597-.05-.598-.05-.598-.054-1.215v-.684c0-.23.004-.465.004-.703 0-.234-.004-.465-.004-.703v-.684c0-.203 0-.41.004-.617.055-.652.23-1.183.45-1.797.081-.414.081-.414.12-.8.016-.141.032-.278.047-.422l.047-.426c.16-1.5.16-1.5.387-1.95.281-2.015.258-3.992 0-6zm0 0"
        ></path>
        <path
          fill="#F96807"
          d="M24.602 10.398c.785-.093 1.28-.011 1.937.415.91.714 1.191 1.554 1.371 2.68.13 1.112.125 2.226.13 3.343 0 .215.003.43.007.648v1.188c-.078.848-.379 1.426-.848 2.129-.14.398-.273.797-.398 1.199h-.403c-.16-.727-.203-1.332-.097-2.063.031-.242.066-.488.097-.738A4.79 4.79 0 0026.2 19c-.027-.379-.039-.758-.047-1.137a40.93 40.93 0 01-.02-.71 94.11 94.11 0 01-.034-1.485c-.04-1.336-.098-2.586-.496-3.867L25 11.6V11.2h-.398v-.8zm0 0"
        ></path>
        <path
          fill="#E7701D"
          d="M15 13.398h.398c-.375 1.176-.375 1.176-.597 1.403-.406 1.601-.715 3.394-.215 5 .629.61 1.379.785 2.215 1-.117.574-.172.773-.602 1.199-.867.133-1.781.184-2.597-.176-.801-.605-1.043-1.262-1.204-2.222-.128-1.457-.03-2.618.915-3.79.289-.21.289-.21.687-.21l.2-1.403c.265-.066.527-.133.8-.199v-.602zm0 0"
        ></path>
        <path
          fill="#DF7C35"
          d="M19.8 12c.985.984.65 3.043.653 4.355-.015 1.891-.215 3.743-.437 5.622-.164 1.406-.278 2.793-.278 4.21-.004.227-.004.227-.004.458.012.804.11 1.433.465 2.156.684.082 1.2.133 1.793-.254.348-.305.684-.617 1.008-.945.2-.2.2-.2.398-.403-.062.574-.062.574-.199 1.2l-.597.402c-.067.195-.133.394-.204.597.266.067.532.133.801.204-1.203.855-2.14.894-3.597.796V30.2l.414-.035c.18-.016.359-.035.547-.05.18-.016.355-.036.542-.052.52-.05.52-.05 1.094-.261v-.403c-.133.02-.265.036-.402.051-.965.07-1.438.074-2.223-.535-1.308-2.309-.195-6.48.14-8.98.415-2.567.415-2.567.184-5.122-.132-.558-.117-1.09-.109-1.664 0-.214.004-.43.004-.652.004-.164.004-.328.008-.496zm0 0"
        ></path>
        <path
          fill="#FDF9E7"
          d="M17.71 11.988c.243.004.243.004.49.012.554.957.468 1.871.464 2.95 0 .214-.004.425-.004.648a32.877 32.877 0 01-.36 4.379 39.394 39.394 0 00-.386 5.21c-.008.215-.012.43-.016.649.004 1.371.247 2.363.903 3.562.133.27.265.536.398.801-.328-.066-.66-.133-1-.199v-1h-.398c-.387-1.46-.465-2.816-.45-4.324v-.711c.028-2.418.075-4.934.56-7.309.116-.59.124-1.156.128-1.758l.008-.668c-.047-.64-.149-1.066-.445-1.628L17 12.199c.2-.199.2-.199.71-.21zm0 0"
        ></path>
        <path
          fill="#FBF1DD"
          d="M12.8 1.8c-.398.196-.398.196-.8.4l.2.6-.49.095c-1.269.277-2.065.468-2.91 1.503-.37.313-.75.618-1.128.915-.34.292-.34.292-.274.886l-.335.04c-.848.296-1.348 1.007-1.875 1.71-.407.473-.583.586-1.188.653l-.2.796a12.755 12.755 0 01-.402-.199c.43-1.258 1.301-2.054 2.204-3 .16-.176.324-.351.492-.531C7.144 4.566 8.21 3.656 9.602 3H10v-.398c.316-.176.633-.344.95-.516.175-.094.35-.188.534-.285.559-.215.77-.215 1.317 0zm-.6.4l.402.198zm0 0"
        ></path>
        <path
          fill="#E17026"
          d="M25.8 22.8c.4.598.4.598.345 1.083-.043.176-.086.347-.133.531-.211.711-.211.711-.012 1.387-.555 1.297-1.422 2.812-2.602 3.597-.535.028-.535.028-1 0 .079-.386.079-.386.204-.796.128-.067.261-.133.398-.204.16-.386.16-.386.29-.847.222-.719.483-1.324.91-1.95.198.063.398.13.6.2l.063-.762c.082-.695.192-.984.739-1.437.14-.422.14-.422.199-.801zm0 0"
        ></path>
        <path
          fill="#DF7830"
          d="M18.8 10.602c.575.5.575.5 1 1-.066.128-.132.261-.198.398-.141-.133-.282-.266-.426-.398-.684-.477-.961-.52-1.778-.403-.824.293-1.125.477-1.574 1.239-.074.187-.148.37-.222.562.128.066.261.133.398.2v-.4l.398.2-.347.488c-.528.785-.887 1.64-1.25 2.512h-.2c-.113-1.125.208-1.68.797-2.602H15V14c-.2.066-.395.133-.602.2-.246.702-.246.702-.398 1.402h-.398c-.067.195-.133.394-.204.597H13c.422-2.023 1.602-3.726 3.2-5 .812-.515 1.64-.765 2.6-.597zm0 0"
        ></path>
        <path
          fill="#F76804"
          d="M26.398 15.2c.067.132.133.265.204.402H27V16h.398c.27.809.239 1.555.239 2.398.004.165.004.329.008.5v.477c.003.145.003.29.003.438-.046.386-.046.386-.449.988-.14.398-.273.797-.398 1.199h-.403c-.226-1.008-.148-1.79 0-2.8v-.598l.106-.461c.113-.63.07-1.168.008-1.805-.02-.211-.04-.426-.063-.645a23.42 23.42 0 00-.05-.492zm0 0"
        ></path>
        <path
          fill="#DD762C"
          d="M8.398 36.2c.352.175.704.35 1.051.527l.668.328c.43.218.856.437 1.278.664 2.812 1.484 5.578 1.879 8.707 1.992.332.016.332.016.671.027.543.02 1.082.043 1.625.063V40h-9.597v-.398c.527.062 1.054.128 1.597.199v-.403c-.14-.023-.285-.046-.433-.074a9.007 9.007 0 01-1.578-.523c-.594-.25-1.16-.461-1.785-.602v-.398l-.356-.07c-.48-.141-.793-.329-1.195-.618a21.528 21.528 0 01-.371-.261l-.282-.25v-.403zm0 0"
        ></path>
        <path
          fill="#FCE4C2"
          d="M15.398 14.2h.204c.07 1.16-.149 1.929-.602 3h-.398v2H15c.324.277.324.277.602.6v.4c.191.03.386.058.585.09.614.108.614.108.813.312.04.687.04.687 0 1.398-.398.398-.398.398-.863.453-.176 0-.356-.004-.535-.004-.27.004-.27.004-.54.004-.46-.055-.46-.055-.863-.453.137-.004.278-.004.418-.008.543-.031 1.051-.07 1.582-.191.446-.477.446-.477.602-1-.25-.016-.496-.035-.75-.051-.621-.078-.828-.133-1.34-.523-.703-.961-.621-2.086-.512-3.227.29-.984.739-1.887 1.2-2.8zm0 0"
        ></path>
        <path
          fill="#D78141"
          d="M17.059-.023h.52c.187 0 .37.003.562.003h.574c.61.004 1.215.004 1.824.008.41 0 .82 0 1.234.004 1.008 0 2.016.004 3.028.008v.398c.14.016.277.028.422.04.273.023.273.023.55.05l.551.047c.477.067.477.067.875.266v.398c.258.016.512.035.778.051.433.027.433.027.824.148.25.41.25.41.398.801a99.785 99.785 0 01-2.789-.855c-.137-.043-.273-.09-.418-.133l-.816-.27C23.356.36 21.55.36 19.648.351c-.238-.003-.476-.011-.726-.015-1.426-.004-2.668.14-4.04.555C14.399 1 14.399 1 13.802.8c2.472-.824 2.472-.824 3.258-.824zm0 0"
        ></path>
        <path
          fill="#FEE7CB"
          d="M10.8 37.398c.294.02.583.04.876.063.242.02.242.02.492.035.434.106.434.106.832.703.395.117.395.117.824.137l.778.062c.062.2.128.399.199.602h1.8v.398h6.797v.204c-3.91.875-8.68.222-12.171-1.72-.141-.093-.282-.187-.426-.28v-.204zm0 0"
        ></path>
        <path
          fill="#FCEAD1"
          d="M38.398 12.8c1.309 1.31 1.418 3.821 1.45 5.56-.004.472-.004.945-.012 1.413v.727c-.004.996-.012 1.926-.234 2.898h-.204c-.128-2.308-.261-4.617-.398-7h-.2c-.327-1.226-.44-2.328-.402-3.597zm0 0"
        ></path>
        <path
          fill="#D3834A"
          d="M.602 13.8H1l-.082.427c-.05.28-.05.28-.106.574l-.105.562c-.695 4.133-.453 8.262.895 12.239-.489-.176-.489-.176-1-.602-.11-.527-.141-1.027-.153-1.563C.434 25.294.418 25.148.4 25A18.936 18.936 0 000 24.8a377.049 377.049 0 01-.031-3.64c-.004-.41-.008-.824-.016-1.234-.05-4.395-.05-4.395.649-6.125zm0 0"
        ></path>
        <path
          fill="#DC803F"
          d="M39.8 18.2h.2v7.402h-.602l.141.546c.063.653.063.653-.203 1.125-.336.329-.336.329-.734.329v.796l-.403.204c-.129.503-.129.503-.199 1h-.602c.207-.684.45-1.301.79-1.926.535-1.04.769-2.14 1.011-3.278.035-.144.067-.293.102-.441.277-1.266.379-2.492.426-3.781l.023-.582c.016-.465.035-.93.05-1.395zm0 0"
        ></path>
        <path
          fill="#F56907"
          d="M15 13.398h.398c-.054.172-.113.34-.171.516-.227.691-.43 1.383-.625 2.086h-.403c-.523 1.176-.437 2.34-.398 3.602-.332-.067-.66-.133-1-.204-.227-.949-.227-.949 0-1.398.047-.453.074-.906.105-1.363.114-.524.274-.715.696-1.035H14l.2-1.403c.265-.066.527-.133.8-.199v-.602zm0 0"
        ></path>
        <path
          fill="#FDECCF"
          d="M19.2 10.2c.55.448.55.448.8 1-.05.464-.05.464-.2.8-.6-.75-.6-.75-.6-1.2-1.345-.07-2.313.067-3.395.903-1.496 1.367-2.41 3.18-3.004 5.098-.278-.766-.176-1.418 0-2.2l.597-.402c.149-.511.149-.511.204-1h.597L14.602 12h.597v-.602c.266-.062.528-.128.801-.199v-.398h.602v-.403c.789-.394 1.738-.312 2.597-.199zm0 0"
        ></path>
        <path
          fill="#FDDDB9"
          d="M20.2 12.8h.402v8h-.403c.004.204.012.407.016.618.031 2.156-.137 4.246-.414 6.383h-.2a41.877 41.877 0 01.297-6.488c.344-2.848.368-5.649.301-8.512zm0 0"
        ></path>
        <path
          fill="#DE772C"
          d="M24.602 10.398c.785-.093 1.28-.011 1.937.415.91.714 1.191 1.554 1.371 2.68.133 1.116.125 2.234.13 3.359 0 .214.003.43.007.648V18.695c-.05.528-.18.852-.445 1.305l-.012-.547c-.016-.676-.035-1.351-.055-2.023-.008-.293-.012-.586-.02-.875-.007-.422-.019-.84-.03-1.262-.008-.254-.016-.504-.02-.766-.067-.754-.25-1.402-.465-2.129v-.796a17.659 17.659 0 00-1.8-.801c-.067.261-.134.527-.2.8V11.2h-.398v-.8zm0 0"
        ></path>
        <path
          fill="#FCE8CB"
          d="M27.8 19.2h.2c.258 1.714.258 1.714 0 2.402h-.398l.046.535c0 1.718-.757 3.613-1.648 5.062-.426.14-.426.14-.8.2a14.7 14.7 0 01.76-1.973c.888-1.961 1.497-4.102 1.84-6.227zm0 0"
        ></path>
        <path
          fill="#E07A31"
          d="M18.398 12.398c.2.067.399.133.602.204.781 2.043.047 4.937-.398 7-.079.375-.153.75-.227 1.125l-.176.875c-.265-.754-.14-1.348-.012-2.125.348-2.344.247-4.715.211-7.079zm0 0"
        ></path>
        <path
          fill="#FBE5C8"
          d="M25 27.398c0 .602 0 .602-.2 1.204h-.402v.796H24l-.2.801-1.402.403V31c-.789-.066-1.582-.133-2.398-.2v-.402c.156-.011.309-.027.469-.043 1.62-.207 2.761-.585 3.8-1.894.188-.277.36-.57.532-.86.066-.066.133-.132.199-.203zm0 0"
        ></path>
        <path
          fill="#F3690D"
          d="M12.8 0h3.4c-.641.426-1.114.535-1.852.688-.114.039-.23.074-.348.113-.066.133-.133.261-.2.398-.421.192-.855.363-1.288.528-.235.085-.469.175-.711.27-.602.202-.602.202-1 .202v-.398h.597l.141-.387c.262-.414.262-.414.785-.562.235-.028.235-.028.477-.051V0zm0 0"
        ></path>
        <path
          fill="#F96702"
          d="M18.602 22.602h.199c.238 1.125.222 2.226.21 3.375 0 .191 0 .386-.003.59 0 .476-.004.956-.008 1.433l-.398.2c-.528-1.376-.438-2.743-.403-4.2h.403v-1.398zm0 0"
        ></path>
        <path
          fill="#FDEDD3"
          d="M6.2 32.602h.198c.02.18.036.363.051.546.172.739.403.957.95 1.454V35l.363.063c.5.156.715.332 1.039.738v.398c.261.067.527.133.8.2v.402c-1.808-.617-3.242-2.13-4.402-3.602.13-.062.258-.12.387-.187.402-.18.402-.18.613-.41zm0 0"
        ></path>
        <path
          fill="#E07124"
          d="M36.2 8.2h.402v.402h.597c.223.5.442 1 .664 1.5.094.207.094.207.188.421.418.946.789 1.907 1.148 2.875.078.168.156.332.239.5.234.72.187 1.352.164 2.102h-.204c-.05-.227-.105-.457-.16-.691-.23-.914-.535-1.79-.875-2.672-.062-.157-.12-.313-.183-.473a15.114 15.114 0 00-1.723-3.172c-.258-.39-.258-.39-.258-.793zm0 0"
        ></path>
        <path
          fill="#FEDAB1"
          d="M17.71 11.988c.243.004.243.004.49.012.554.957.468 1.871.464 2.95 0 .21-.004.417-.004.636-.027 2.293-.316 4.547-.66 6.812h-.2c-.042-1.191.005-2.32.2-3.5.184-1.18.246-2.328.25-3.523 0-.164.004-.328.004-.496.008-1.102.008-1.102-.453-2.078-.406-.348-.406-.348-.801-.602.2-.199.2-.199.71-.21zm0 0"
        ></path>
        <path
          fill="#FDF8E8"
          d="M24.2 10l.976-.012c.18-.004.36-.004.547-.008.476.02.476.02.879.22v.6c.195.067.394.134.597.2-.8 0-.8 0-1.398-.398a20.759 20.759 0 00-1.2-.204v.801H25c.352.617.398.848.398 1.602h-.597c-.086-.223-.086-.223-.176-.45-.203-.539-.203-.539-.46-.953-.216-.527-.114-.859.034-1.398zm0 0"
        ></path>
        <path
          fill="#E57B2B"
          d="M34 33.8h.398v.4H34l-.2 1h-.402l-.14.366c-.32.54-.617.692-1.172.973-.582.273-.582.273-1.086.66-.332.008-.668.008-1 0 .637-.719 1.375-1.195 2.176-1.707.43-.277.43-.277.754-.629.27-.261.27-.261.672-.261.128-.266.261-.532.398-.801zm0 0"
        ></path>
        <path
          fill="#EB711C"
          d="M20.852 29.188c.25.039.503.078.765.117l.582.093v.403c-.683.453-.992.469-1.8.488l-.637.023c-.563-.113-.563-.113-.938-.617-.074-.164-.148-.328-.222-.496.777-.281 1.445-.137 2.25-.012zm0 0"
        ></path>
        <path
          fill="#FDEBCC"
          d="M27.8 37.398c.74-.035.74-.035 1.4 0-1.47 1.196-3.352 1.727-5.2 2l.2-.796c.316-.055.316-.055.636-.114l.828-.152c.137-.023.273-.047.418-.07.773-.145 1.2-.282 1.719-.868zm0 0"
        ></path>
        <path
          fill="#FDDDBB"
          d="M17.602 22.602h.199c0 .14.004.28.004.43.011.652.027 1.304.047 1.956 0 .223.003.446.007.676.04 1.426.254 2.488.942 3.734.133.27.265.536.398.801-.328-.066-.66-.133-1-.199v-1h-.398c-.328-1.152-.438-2.23-.438-3.426v-.402c.008-.879.098-1.711.239-2.57zm0 0"
        ></path>
        <path
          fill="#FEE8CA"
          d="M19.45.137h.76c1.86.02 3.59.195 5.392.664V1l-.891.012-.496.008C23.8 1 23.8 1 23.602.8 21.082.52 18.532.579 16 .603c1.059-.637 2.254-.47 3.45-.465zm0 0"
        ></path>
        <path
          fill="#FEECCE"
          d="M25.398 19.2h.403c-.094 2.226-.684 4.3-1.403 6.402H24.2c-.023-1.528-.023-1.528.2-2.204h.402V21.2c.133-.066.262-.133.398-.199.094-.426.094-.426.125-.914l.047-.5c.008-.125.02-.254.027-.387zm0 0"
        ></path>
        <path
          fill="#E07F33"
          d="M37.2 29.602c.124.375.124.375.198.796-.128.133-.261.266-.398.403-.035.515-.035.515 0 1-.188.066-.188.066-.375.137-.555.34-.605.664-.824 1.261-.278.375-.278.375-.602.602-.418-.086-.418-.086-.8-.2.257-.37.515-.734.777-1.101l.433-.617.391-.485h.398c.059-.148.118-.296.176-.449.192-.46.395-.906.625-1.347zm0 0"
        ></path>
        <path
          fill="#F76604"
          d="M25.8 22.8c.4.598.4.598.352 1.095l-.129.543-.12.546c-.036.137-.067.274-.102.414h-.403c-.062.332-.128.66-.199 1h-.398v.403h-.403v-1.2c.2.098.2.098.403.2.02-.254.039-.504.062-.762.082-.695.192-.984.739-1.437.14-.422.14-.422.199-.801zm0 0"
        ></path>
        <path
          fill="#DF7129"
          d="M23.2 27.2c.198.6.198.6.038 1.027-.238.375-.238.375-.636.574-.067.195-.133.394-.204.597.266.067.532.133.801.204-1.203.855-2.14.894-3.597.796V30.2l.414-.035c.18-.016.359-.035.547-.05.269-.024.269-.024.542-.052.52-.05.52-.05 1.094-.261v-.403h-2V29.2l.375-.008A7.63 7.63 0 0022 29c.398-.445.398-.445.602-1 .195-.27.394-.535.597-.8zm0 0"
        ></path>
        <path
          fill="#FDEECD"
          d="M35.2 32c.132.066.265.133.402.2-.957 1.394-2.036 2.683-3.602 3.402.148-.489.148-.489.398-1 .426-.164.426-.164.801-.204v-.597h.403v-.403c.195-.062.394-.128.597-.199.137-.265.27-.531.403-.8.195-.13.394-.262.597-.399zm0 0"
        ></path>
        <path
          fill="#E4782A"
          d="M0 22.398h.398c.04.211.075.418.114.633.277 1.555.578 3.07 1.09 4.57-.489-.175-.489-.175-1-.601-.11-.527-.141-1.027-.153-1.563C.434 25.294.418 25.148.4 25A18.936 18.936 0 000 24.8v-2.402zm0 0"
        ></path>
        <path
          fill="#DD7630"
          d="M3 8.602c.2.062.395.128.602.199-.266.527-.532 1.054-.801 1.597h-.403a71.307 71.307 0 01-1.2 3.402h-.198c.098-1.323.469-2.39 1-3.6h.398v-.802H3v-.796zm0 0"
        ></path>
        <path
          fill="#FDE5C8"
          d="M30.602 3.2c.691.398.691.398 1.398.8v.398c.145.043.29.086.438.125C33.184 4.891 33.5 5.34 34 6l-.2.398h-.402a39.567 39.567 0 00-.199-.796c-.261-.067-.527-.133-.8-.204V6H32V4.8a62.132 62.132 0 01-.586-.237c-.613-.364-.613-.364-.789-.899l-.023-.465zm0 0"
        ></path>
        <path
          fill="#D9752E"
          d="M17.8 22.398h.2l.023.801c.079 2.54.079 2.54.672 5 .106.403.106.403-.093 1-.633-.633-.782-1.332-.848-2.21.004-.216.004-.427.008-.645 0-.23 0-.461.004-.7.004-.238.007-.472.007-.718.004-.242.004-.485.008-.735.004-.597.012-1.195.02-1.793zm0 0"
        ></path>
        <path
          fill="#FEECD1"
          d="M20 21.602h.2c.066 2.097-.137 4.12-.4 6.199h-.198c-.043-2.106-.063-4.133.398-6.2zm0 0"
        ></path>
        <path
          fill="#F36909"
          d="M23.602 27h.796c-.082.29-.171.574-.261.863-.047.16-.098.32-.149.485-.203.488-.363.742-.789 1.05h-.8c.078-.386.078-.386.203-.796.128-.067.261-.133.398-.204.21-.46.41-.925.602-1.398zm0 0"
        ></path>
        <path
          fill="#E96A15"
          d="M39.602 12.8H40v5.4h-.2c-.073-.598-.144-1.2-.214-1.802-.02-.168-.04-.335-.063-.507a10.64 10.64 0 01.079-3.09zm0 0"
        ></path>
        <path
          fill="#DE7E37"
          d="M25.602 20.398h.199c.07 1.235-.016 2.211-.457 3.368-.149.449-.211.859-.258 1.336-.027.23-.055.46-.086.699l-.398.199c-.278-.977-.122-1.559.218-2.469.344-1.02.551-2.078.782-3.133zm0 0"
        ></path>
        <path
          fill="#F9D5AE"
          d="M14.8 20c1.09.297 1.09.297 2.2.602.023.671.023.671 0 1.398-.398.398-.398.398-.863.453-.176 0-.356-.004-.535-.004-.27.004-.27.004-.54.004-.46-.055-.46-.055-.863-.453l.418-.008c.543-.031 1.051-.07 1.582-.191.446-.477.446-.477.602-1-.406-.035-.809-.067-1.211-.098-.39-.101-.39-.101-.79-.703zm0 0"
        ></path>
        <path
          fill="#FCEACE"
          d="M10.8 37.398c.294.02.583.04.876.063.242.02.242.02.492.035.434.106.434.106.832.703.395.117.395.117.824.137l.778.062V39c-1.415-.25-2.543-.71-3.801-1.398v-.204zm0 0"
        ></path>
        <path
          fill="#E27626"
          d="M14.8 13h.2v1c-.2.066-.395.133-.602.2-.246.702-.246.702-.398 1.402h-.398c-.067.195-.133.394-.204.597H13c.137-.62.348-1.168.613-1.75.11-.238.11-.238.219-.484.055-.121.11-.242.168-.363h.602c.062-.2.128-.399.199-.602zm0 0"
        ></path>
        <path
          fill="#E37528"
          d="M16.398 11.398l2 .403c-.062.133-.128.261-.199.398-.226-.02-.226-.02-.46-.035-.641.043-.723.184-1.137.637H16v.398c-.2-.066-.395-.133-.602-.199.332-.527.66-1.055 1-1.602zm0 0"
        ></path>
        <path
          fill="#FCDFBC"
          d="M27.398 11.8h.403c.234.735.215 1.434.199 2.2h.398c.024 1.223-.02 2.39-.199 3.602H28c-.016-.211-.016-.211-.035-.426l-.152-1.914c-.016-.219-.036-.442-.055-.668a38.831 38.831 0 00-.051-.645l-.047-.594a15.013 15.013 0 00-.262-1.554zm0 0"
        ></path>
        <path
          fill="#DD7C37"
          d="M32.398 4.398c.696.247 1.09.489 1.602 1 .137.028.273.051.414.079.125.039.254.082.387.125.14.328.273.664.398 1 .313.421.653.804 1 1.199-.066.133-.133.261-.199.398-.52-.5-.996-1.023-1.46-1.574-.657-.758-1.372-1.383-2.142-2.023v-.204zm0 0"
        ></path>
        <path
          fill="#D7813E"
          d="M18.188 10.523c.82.102 1.09.461 1.613 1.079-.067.128-.133.261-.2.398-.14-.133-.28-.266-.425-.398-.684-.477-.961-.52-1.778-.403-.402.192-.804.39-1.199.602h-.597c.906-.805 1.386-1.121 2.585-1.278zm0 0"
        ></path>
        <path
          fill="#DD742E"
          d="M15 20.398l1.8.403c-.198.8-.198.8-.6 1.199-.505.055-.505.055-1.098.05-.2 0-.395 0-.594.005C14 22 14 22 13.602 21.602c.789.062 1.582.128 2.398.199v-.602c-.328-.066-.66-.133-1-.199v-.602zm0 0"
        ></path>
        <path
          fill="#FA6703"
          d="M13 20c.742-.04.953-.031 1.602.398.234.528.234.528.398 1-.695.082-.96.028-1.563-.359L13 20.602V20zm0 0"
        ></path>
        <path
          fill="#E18242"
          d="M28 38.2c-.125.386-.125.386-.398.8-.551.16-.551.16-1.204.238-.828.086-.828.086-1.597.364-.469.007-.934.007-1.403 0v-.204c.114-.027.227-.058.344-.086.512-.132 1.024-.265 1.531-.398.18-.047.356-.094.54-.14.257-.067.257-.067.515-.137.16-.04.317-.082.477-.121 1.082-.317 1.082-.317 1.195-.317zm0 0"
        ></path>
        <path
          fill="#EA680E"
          d="M26.2 14h.198c.086.688.165 1.375.239 2.063.023.195.05.39.074.593l.059.567c.02.172.043.343.062.523-.031.453-.031.453-.633 1.055V14zm0 0"
        ></path>
        <path
          fill="#FEF7E9"
          d="M28.398 14.8h.204l.011 1.637c0 .153.004.31.004.465.004.793-.02 1.524-.219 2.297H28c.027-.508.059-1.015.086-1.523l.023-.434c.051-.824.137-1.625.29-2.441zm0 0"
        ></path>
        <path
          fill="#F9EBD8"
          d="M12.477 1.664c.105.043.214.09.324.137-.399.195-.399.195-.801.398l.2.602c-.595.133-1.188.261-1.802.398v-.398h.403v-.602c1.082-.613 1.082-.613 1.676-.535zm-.278.535l.403.2zm0 0"
        ></path>
        <path
          fill="#FDECD2"
          d="M22.8 28c-1.01.984-1.01.984-1.976 1.063-.207-.02-.41-.043-.625-.063v-.602c.274-.035.274-.035.551-.074.633-.09.633-.09 1.086-.347.363-.176.363-.176.965.023zm0 0"
        ></path>
        <path
          fill="#FCEAD3"
          d="M39 23.398h.398c-.152 2.305-.152 2.305-.597 3.403h-.2c-.011-.38-.019-.758-.027-1.137l-.011-.64c.039-.63.171-1.06.437-1.626zm0 0"
        ></path>
        <path
          fill="#D68241"
          d="M24.602 10.398c1.015-.12 1.52.133 2.316.704.336.355.402.62.48 1.097-.195.102-.195.102-.398.2v-.797a17.659 17.659 0 00-1.8-.801c-.067.261-.134.527-.2.8V11.2h-.398v-.8zm0 0"
        ></path>
        <path
          fill="#FDEACF"
          d="M16 .602V1c-1.145.488-1.957.668-3.2.602.458-.461.759-.547 1.376-.727.258-.078.258-.078.523-.156.5-.117.5-.117 1.301-.117zm0 0"
        ></path>
        <path
          fill="#DC772D"
          d="M38 11.398h.398c.27.668.536 1.336.801 2 .074.172.149.34.227.516.258.719.199 1.324.176 2.086h-.204c-.043-.203-.09-.402-.136-.613-.336-1.352-.813-2.668-1.262-3.989zm0 0"
        ></path>
        <path
          fill="#E67E36"
          d="M4 32c.672.223.758.348 1.137.914.09.125.176.254.265.387.067.097.13.199.2.3-.266.063-.532.13-.801.2a38.785 38.785 0 01-.2-.403c-.406-.226-.406-.226-.8-.398.066-.328.133-.66.199-1zm0 0"
        ></path>
        <path
          fill="#FDF1D8"
          d="M14.398 17.2h.204v2H15c.324.277.324.277.602.6v.4c.593.1.593.1 1.199.198-.492.18-.727.227-1.23.047l-1.173-.644v-2.602zm0 0"
        ></path>
        <path
          fill="#F66808"
          d="M21.438 29.387c.378.008.378.008.761.011v.403c-.691.46-.988.449-1.8.398A18.927 18.927 0 0020 30v-.398c.55-.184.871-.223 1.438-.215zm0 0"
        ></path>
        <path
          fill="#F06B0F"
          d="M25.2 10.8c.488.075.488.075 1 .2l.198.398h-.597c.066.106.133.207.199.313.266.644.227 1.2.2 1.89-.329-.593-.49-1.132-.598-1.8h-.403v-1zm0 0"
        ></path>
        <path
          fill="#FCE8CD"
          d="M3 9.602h.2v1.597c-.13.059-.255.117-.388.176-.44.191-.44.191-.613.625h-.398c.375-1.148.375-1.148.597-1.602h.403c.066-.261.133-.527.199-.796zm0 0"
        ></path>
        <path
          fill="#E1752D"
          d="M11.8 1h2c-.538.36-1.015.586-1.613.824l-.53.215c-.458.16-.458.16-.856.16v-.398h.597L11.801 1zm0 0"
        ></path>
        <path
          fill="#FEEAD1"
          d="M3.2 30.398c.265.067.527.133.8.204v.796h.398v.403h.403v.398h.398c-.066.266-.133.528-.199.801-1.8-2.074-1.8-2.074-1.8-2.602zm0 0"
        ></path>
        <path
          fill="#FDE8CD"
          d="M1.602 12.2H1.8c.09 1.292-.082 1.921-.801 3-.285-.759-.102-1.212.188-1.95.113-.297.113-.297.23-.598.059-.148.121-.297.184-.453zm0 0"
        ></path>
        <path
          fill="#FCE2BF"
          d="M19.398 10.398c.375.364.375.364.602.801-.063.45-.063.45-.2.801-.6-.75-.6-.75-.6-1.2H17c.688-.69 1.5-.706 2.398-.402zm0 0"
        ></path>
        <path
          fill="#E37D37"
          d="M22.398 0h2.403v.602c.195.062.394.128.597.199-.195-.031-.394-.059-.597-.09C24 .598 23.199.5 22.398.398V0zm0 0"
        ></path>
        <path
          fill="#F8D6B3"
          d="M13.8 38.8l2.802.2v.398h1v.204c-1.368.074-2.5-.2-3.801-.602v-.2zm0 0"
        ></path>
        <path
          fill="#DC8640"
          d="M1.602 27.8c.683.782 1.597 1.903 1.597 3h-.398c-.656-.655-.715-1.304-.801-2.198h-.398V27.8zm0 0"
        ></path>
        <path
          fill="#F9DEBC"
          d="M38.398 12.8c.407.403.536.653.739 1.177.054.132.11.27.164.41.113.476.05.754-.102 1.215a16.1 16.1 0 00-.062-.391c-.043-.133-.09-.27-.137-.41-.2-.067-.395-.133-.602-.2v-1.8zm0 0"
        ></path>
        <path
          fill="#FBDBB6"
          d="M9.2 3.2v.6c-.196.067-.395.134-.598.2l-.204.602c-.394.062-.789.128-1.199.199.621-.66 1.203-1.168 2-1.602zm0 0"
        ></path>
        <path
          fill="#F9E1C2"
          d="M28.398 2c1.153.375 1.153.375 1.602.602V3l.398.2c-1.5-.098-1.5-.098-2-.614a18.729 18.729 0 00-.199-.387L28.4 2zm0 0"
        ></path>
        <path
          fill="#FBF0D7"
          d="M30.398 35.602H32c-.496.53-.926.91-1.602 1.199v-1.2zm0 0"
        ></path>
        <path
          fill="#E4762A"
          d="M35.8 32.2c.15.35.15.35.2.8-.336.46-.336.46-.8.8-.45-.05-.45-.05-.802-.198.266-.403.266-.403.602-.801h.602c.062-.2.128-.399.199-.602zm0 0"
        ></path>
        <path
          fill="#FCF9E9"
          d="M19.2 10.2c.378.054.378.054.8.198.21.344.21.344.352.766.046.137.093.274.144.414.106.422.106.422.106 1.223h-.403l-.117-.367c-.05-.16-.102-.32-.156-.485-.051-.156-.102-.316-.156-.476a9.526 9.526 0 00-.57-1.274zm0 0"
        ></path>
        <path
          fill="#F06909"
          d="M18.04 10.95c.183.015.37.034.562.05-.067.266-.133.527-.204.8h-1.597c.363-.648.472-.788 1.238-.85zm0 0"
        ></path>
        <path
          fill="#FBDEBD"
          d="M4.602 7.2c.125.362.125.362.199.8l-.403.602H4l-.2.796a12.755 12.755 0 01-.402-.199c.278-.797.653-1.36 1.204-2zm0 0"
        ></path>
        <path
          fill="#FEF4E3"
          d="M25.8 38.2c.067.132.134.265.2.402h-.398V39c-.532.133-1.059.266-1.602.398l.2-.796 1.6-.403zm0 0"
        ></path>
        <path
          fill="#E47F36"
          d="M30 37.2c-.176.777-.176.777-.398 1-.536.027-1.067.007-1.602 0 1.273-1 1.273-1 2-1zm0 0"
        ></path>
        <path
          fill="#DE782D"
          d="M27.398 20.2h.204A21.181 21.181 0 0127 23.397h-.2c.024-.418.048-.84.075-1.261.016-.235.027-.469.043-.711.082-.625.082-.625.48-1.227zm0 0"
        ></path>
        <path
          fill="#FCF2E1"
          d="M32.2 4.8c1.35.524 1.35.524 1.8 1.2l-.2.398h-.402a39.567 39.567 0 00-.199-.796c-.261-.067-.527-.133-.8-.204V6H32a21.338 21.338 0 010-1l.2-.2zm0 0"
        ></path>
        <path
          fill="#E3701C"
          d="M8.398 3.8c-.261.235-.523.466-.785.7-.148.129-.293.262-.445.395-.367.304-.367.304-.566.304v-1h.597v-.398c.602-.2.602-.2 1.2 0zm0 0"
        ></path>
        <path
          fill="#DC7D39"
          d="M38.602 26.8c.168.614.23.993 0 1.598l-.403.204c-.129.503-.129.503-.199 1h-.602c.301-.997.723-1.887 1.204-2.801zm0 0"
        ></path>
        <path
          fill="#FBDEBA"
          d="M26.2 25c.32.8.058 1.41-.2 2.2-.266.066-.527.132-.8.198.26-.843.59-1.613 1-2.398zm0 0"
        ></path>
        <path
          fill="#E17E39"
          d="M12.8 16.398h.2c.043 1.16-.082 2.086-.398 3.204h-.204c-.039-1.149-.05-2.122.403-3.204zm0 0"
        ></path>
        <path
          fill="#FEE2C3"
          d="M12.398 16.8c.243.75.223 1.466.215 2.25-.004.25-.004.505-.008.766 0 .192-.003.387-.003.582-.465-.691-.489-1.085-.551-1.91l-.059-.703c.008-.586.008-.586.406-.984zm0 0"
        ></path>
        <path
          fill="#FAFAF0"
          d="M1.2 14.602c.206.636.19 1.07.038 1.722-.05.235-.05.235-.105.473-.133.402-.133.402-.531.805.02-.391.046-.778.074-1.165.011-.214.027-.43.043-.652.082-.586.082-.586.48-1.183zm0 0"
        ></path>
        <path
          fill="#FEE4C4"
          d="M38.2 26.8h.402c-.23.891-.473 1.641-1 2.4h-.204c-.035-.575-.035-.575 0-1.2.2-.133.399-.266.602-.398.145-.415.145-.415.2-.801zm0 0"
        ></path>
        <path
          fill="#FBE2BC"
          d="M37.602 10.8c.566.641.703 1.157.796 2h-.597c-.32-.644-.211-1.288-.2-2zm0 0"
        ></path>
        <path
          fill="#E56D11"
          d="M39.602 23.602c.398.398.398.398.437.789-.012.402-.027.804-.039 1.21h-.8l.402-2zm0 0"
        ></path>
        <path
          fill="#FCDCB9"
          d="M16.8 12.398c.2.403.2.403.075.903-.273.5-.273.5-.8.761-.157.047-.313.09-.473.137.304-.687.718-1.222 1.199-1.8zm0 0"
        ></path>
        <path
          fill="#FAE0BF"
          d="M24.398 10.602c.067.195.133.394.204.597H25c.352.617.398.848.398 1.602h-.597c-.238-.778-.403-1.383-.403-2.2zm0 0"
        ></path>
        <path
          fill="#DC7A34"
          d="M5.8 5.2c.263.066.528.132.802.198L5 7c-.066-.2-.133-.395-.2-.602.196-.062.395-.128.598-.199v-.597l.403-.403zm0 0"
        ></path>
        <path
          fill="#DF7F38"
          d="M30.8 3.2c.552.105 1.063.222 1.598.402V4l.403.2c-.266.066-.528.132-.801.198V4c-.16-.059-.32-.117-.488-.176C31 3.602 31 3.602 30.8 3.2zm0 0"
        ></path>
        <path
          fill="#E47C2E"
          d="M24.2 25.602h.198c.067.527.133 1.054.204 1.597h-1.204c.227-.594.446-1.066.801-1.597zm0 0"
        ></path>
        <path
          fill="#FAE0BF"
          d="M24.398 23.398H25a13.52 13.52 0 01-.602 2.204H24.2c-.023-1.528-.023-1.528.2-2.204zm0 0"
        ></path>
        <path
          fill="#FBE8CD"
          d="M12.602 20.398l.359.465c.332.407.66.778 1.039 1.137-.75.227-.75.227-1.2 0-.12-.531-.148-1.059-.198-1.602zm0 0"
        ></path>
        <path
          fill="#E07A32"
          d="M10.8 2.398c-.265.067-.527.133-.8.204V3l-1.398.602V3h.597v-.398c.926-.426.926-.426 1.602-.204zm0 0"
        ></path>
        <path
          fill="#FEE7C6"
          d="M1.602 26.602c.847 1.273.847 1.273.796 2.199-.574-.653-.925-1.121-1-2 .067-.067.133-.133.204-.2zm0 0"
        ></path>
        <path
          fill="#F06A0B"
          d="M19.2 21c.132.066.265.133.402.2v2.198h-.204v-1H19c.066-.46.133-.921.2-1.398zm0 0"
        ></path>
        <path
          fill="#F36809"
          d="M16.2 11.602h.402c-.067.394-.133.789-.204 1.199H16v.398c-.2-.066-.395-.133-.602-.199.266-.46.532-.926.801-1.398zm0 0"
        ></path>
        <path
          fill="#FBDEBB"
          d="M24.8 10.2h1.802v.6c.195.067.394.134.597.2-.8 0-.8 0-1.398-.398a14.621 14.621 0 00-1-.204V10.2zm0 0"
        ></path>
        <path
          fill="#E17E37"
          d="M3 8.602c.2.062.395.128.602.199-.266.527-.532 1.054-.801 1.597h-.403v-1H3v-.796zm0 0"
        ></path>
        <path
          fill="#FDE6C3"
          d="M34 6c1.176.926 1.176.926 1.398 1.602L34.2 7.199C34.133 6.805 34.066 6.406 34 6zm0 0"
        ></path>
        <path
          fill="#F2FCFA"
          d="M33.8 6.8h.4v.4c.265.132.527.265.8.402-.328.128-.66.261-1 .398a89.06 89.06 0 01-.398-1 4.79 4.79 0 01.199-.2zm0 0"
        ></path>
        <path
          fill="#DD762D"
          d="M6.8 35c.61.348 1.126.676 1.598 1.2-.46-.067-.921-.134-1.398-.2l-.2-1zm0 0"
        ></path>
        <path
          fill="#FBFBF0"
          d="M25 27.8l.602.4c-.067.265-.133.527-.204.8h-.597c-.2.098-.2.098-.403.2v-.598h.403L25 27.8zm0 0"
        ></path>
        <path fill="#FDFAE4" d="M12 18h.2l.402 2.8H12V18zm0 0"></path>
        <path
          fill="#FBDBB8"
          d="M7.602 35.398l1.199.403v.398c.261.067.527.133.8.2v.402c-.886-.281-1.374-.723-2-1.403zm0 0"
        ></path>
        <path
          fill="#DD8341"
          d="M33.2 34.602c.066.128.132.261.198.398-.222.523-.222.523-.597 1-.54.063-.54.063-1 0l1.398-1.398zm0 0"
        ></path>
        <path
          fill="#FEF7E7"
          d="M19.8 26.602c.438.656.598 1.238.802 2-.266-.133-.532-.266-.801-.403v-1.597zm0 0"
        ></path>
        <path
          fill="#DB7534"
          d="M12.602 19.602L13 19.8l.2.8c.132.063.265.13.402.2-.067.262-.133.527-.204.8-.558-.413-.605-.628-.734-1.34a34.44 34.44 0 01-.062-.66zm0 0"
        ></path>
        <path
          fill="#FBE4C8"
          d="M17.71 11.988c.243.004.243.004.49.012v1.602l-.15-.364c-.284-.496-.581-.726-1.05-1.039.2-.199.2-.199.71-.21zm0 0"
        ></path>
        <path
          fill="#E1711D"
          d="M4.2 7l.6.2-1 1.402c-.214-.375-.214-.375-.402-.801.067-.133.133-.266.204-.403h.597V7zm0 0"
        ></path>
        <path
          fill="#E77626"
          d="M24.602 24.398H25v1.403c-.2.097-.2.097-.398.199-.227-1.148-.227-1.148 0-1.602zm0 0"
        ></path>
        <path
          fill="#E96D16"
          d="M26.2 19c.132.066.265.133.402.2-.067.527-.133 1.058-.204 1.6h-.597c.097-.624.199-1.198.398-1.8zm0 0"
        ></path>
        <path
          fill="#FDEBD4"
          d="M15.398 14.2h.204c.074 1.304.074 1.304-.301 1.788-.102.07-.2.14-.301.211-.035-.789-.02-1.32.398-2zm0 0"
        ></path>
        <path
          fill="#FDF7F0"
          d="M4 8.602c.2.062.395.128.602.199-.067.261-.133.527-.204.8H3.2V9.2h.602c.066-.195.133-.394.199-.597zm0 0"
        ></path>
        <path
          fill="#FEE0C0"
          d="M23.2.398c.808.114 1.605.235 2.402.403V1c-.29.016-.579.027-.864.04-.242.007-.242.007-.484.019-.55-.07-.711-.239-1.055-.66zm0 0"
        ></path>
        <path
          fill="#E07528"
          d="M5.602 33.8c.394.329.789.66 1.199 1-.5.25-.684.157-1.2 0v-1zm0 0"
        ></path>
        <path
          fill="#FFF2D8"
          d="M35.2 32c.132.066.265.133.402.2-.258.343-.524.675-.801 1h-.403c.063-.376.063-.376.204-.802.195-.128.394-.261.597-.398zm0 0"
        ></path>
        <path
          fill="#E4823B"
          d="M23.2 27.2c.198.6.198.6.01 1.038l-.21.364c-.266-.067-.527-.133-.8-.204.331-.394.66-.789 1-1.199zm0 0"
        ></path>
        <path
          fill="#D87B34"
          d="M29.2 2.2c.573.05.573.05 1.198.198.278.414.278.414.403.801-.266-.066-.528-.133-.801-.199v-.398l-.8-.403zm0 0"
        ></path>
      </g>
    </>
  );
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          ...props.sx,
        },
      }}
      {...props}
    />
  );
}
