import { Checkbox, Collapse, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";

const NormalCheckBox = ({
  control,
  name,
  label,
  defaultChecked = false,
  stackSx = {},
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultChecked}
        render={({ field, fieldState: { error } }) => (
          <Stack sx={{ mt: "28px", ...stackSx }}>
            <FormControlLabel
              sx={{
                "&.MuiFormControlLabel-root": {
                  alignItems: "flex-start",
                },
                "& .MuiCheckbox-root": {
                  paddingBlock: 0,
                  paddingInline: "8px",
                },
              }}
              control={<Checkbox {...field} checked={field.value} />}
              label={label}
            />
            <Collapse in={Boolean(error)}>
              <ErrorText error={error} />
            </Collapse>
          </Stack>
        )}
      />
    </>
  );
};

export default NormalCheckBox;
