import React from "react";
import { createSvgIcon, useTheme } from "@mui/material";
export default function WarningIcon(props) {
  const { text } = useTheme();
  const color = props.color ? props.color : text.secondary;
  const SvgIcon = createSvgIcon(
    <>
      <g id="Frame 133">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99995 6.3999C8.44179 6.3999 8.79995 6.75806 8.79995 7.1999V11.6799C8.79995 12.1217 8.44179 12.4799 7.99995 12.4799C7.55811 12.4799 7.19995 12.1217 7.19995 11.6799V7.1999C7.19995 6.75806 7.55811 6.3999 7.99995 6.3999Z"
          fill={color}
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8C16 12.4182 12.4182 16 8 16C3.58172 16 0 12.4182 0 8Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M8.79995 4.32002C8.79995 4.76184 8.44179 5.12002 7.99995 5.12002C7.55811 5.12002 7.19995 4.76184 7.19995 4.32002C7.19995 3.8782 7.55811 3.52002 7.99995 3.52002C8.44179 3.52002 8.79995 3.8782 8.79995 4.32002Z"
          fill={color}
        />
      </g>
    </>
  );
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
        ...props.sx,
      }}
      {...props}
    />
  );
}
