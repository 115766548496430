export function calculateBodyComposition(
  weight,
  waist,
  neck,
  height,
  gender,
  hip,
  goal
) {
  weight = parseFloat(weight);
  waist = parseFloat(waist);
  neck = parseFloat(neck);
  height = parseFloat(height);
  hip = parseFloat(hip);

  let bfPercentage;
  if (gender.toLowerCase() === "male") {
    // Body Fat Percentage formula for males
    bfPercentage =
      86.01 * Math.log10(waist - neck) - 70.041 * Math.log10(height) + 36.76;
  } else if (gender.toLowerCase() === "female") {
    // Body Fat Percentage formula for females
    bfPercentage =
      163.205 * Math.log10(waist + hip - neck) -
      97.684 * Math.log10(height) -
      78.387;
  } else {
    return "Invalid gender provided. Please specify 'male' or 'female'.";
  }

  // Calculate Fat Mass and Lean Mass
  const fatMass = weight * (bfPercentage / 100);
  const leanMass = weight - fatMass;

  const result = {
    fatMass: Number(fatMass.toFixed(1)),
    leanMass: Number(leanMass.toFixed(1)),
    bodyFatPercentage: Math.round(bfPercentage),
    accurateBF: Number(bfPercentage),
  };

  // Check conditions for offering education and tracking
  if (
    goal.toLowerCase() === "fat loss" &&
    ((gender.toLowerCase() === "male" && bfPercentage > 15) ||
      (gender.toLowerCase() === "female" && bfPercentage > 25))
  ) {
    result.education =
      "Your #1 goal is Fat Loss. You want to lose more fat than lean mass every week on average.";
    result.offer =
      "We offer to track your progress and provide personalized guidance to help you achieve your goal.";
  }

  return result;
}

// // Example usage
// const detailsMale = {
//   weight: 180, // in lbs
//   waist: 32, // in inches
//   neck: 15, // in inches
//   height: 70, // in inches
//   gender: "male",
//   goal: "Fat Loss",
// };

// const detailsFemale = {
//   weight: 150, // in lbs
//   waist: 28, // in inches
//   neck: 13, // in inches
//   height: 65, // in inches
//   hip: 36, // in inches
//   gender: "female",
//   goal: "Fat Loss",
// };

// const resultMale = calculateBodyComposition(
//   detailsMale.weight,
//   detailsMale.waist,
//   detailsMale.neck,
//   detailsMale.height,
//   detailsMale.gender,
//   undefined,
//   detailsMale.goal
// );
// console.log(resultMale);

// const resultFemale = calculateBodyComposition(
//   detailsFemale.weight,
//   detailsFemale.waist,
//   detailsFemale.neck,
//   detailsFemale.height,
//   detailsFemale.gender,
//   detailsFemale.hip,
//   detailsFemale.goal
// );
// console.log(resultFemale);
