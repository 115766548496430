import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect, useState } from "react";
import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import MobileNumberInput from "../global/form/MobileNumberInput";
import { SelectInput } from "../global/form/SelectInput";
import PrimaryButton from "../global/button/PrimaryButton";
import { useForm } from "react-hook-form";
import {
  dayOfWeek,
  repetitionInterval,
  timeZones,
} from "../../inputConfigs/reminderSource";
import { ReminderSchema } from "../../validators/ReminderSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import MainContainer from "../global/container/MainContainer";
import { useAuth } from "../../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import useFindData from "../../hooks/useFindData";
import useSetData from "../../hooks/useSetData";
import useUpdateData from "../../hooks/useUpdateData";
import { NewRadioButton } from "../global/form/NewRadioButton";
import NewTimePicker from "../global/form/NewTimePicker";
import ElementLabel from "../global/form/ElementLabel";
import ErrorText from "../global/form/ErrorText";
import OTPFormDialog from "./OTPFormDialog";
import useDeleteData from "../../hooks/useDeleteData";
import useApiCall from "../../hooks/useApiCall";
import { useCommon } from "../../hooks/store/useCommon";
import { matchCountryCode } from "../../utils/SmsSeviceChecker";
const dayjs = require("dayjs");
const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
dayjs.extend(timezone);
const ReminderForm = () => {
  const theme = useTheme();
  const { isLoggedIn, userId, setUseDetails, email, name } = useAuth();
  const { loading, setData } = useSetData();
  const { loading: updateDataLoading, updateData } = useUpdateData();
  const { loading: findDataLoading, findData } = useFindData();
  const { deleteDataById, loading: deleteLoading } = useDeleteData();
  const { apiCall, loading: apiCallLoading } = useApiCall();
  const [showDayOfWeekInput, setShowDayOfWeekInput] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { fromTrigger, settings } = useCommon();

  useEffect(() => {
    const sendReminderMail = async () => {
      const url = `${process.env.REACT_APP_API_URL}/exitWithoutSetReminder`;
      const data = { userId };

      try {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          keepalive: true,
        });
      } catch (error) {
        console.error("Failed to send reminder mail:", error);
      }
    };

    sendReminderMail();
  }, [userId]);

  let email_query = new URLSearchParams(window.location.search).get("email");
  let userId_query = new URLSearchParams(window.location.search).get("userId");

  useEffect(() => {
    if (!isLoggedIn) {
      if ((userId && email) || (userId_query && email_query)) {
        amplitude.logEvent("Reminders_Visited", {
          Trigger: fromTrigger ? fromTrigger : undefined,
        });
        if (!userId && !email) {
          return setUseDetails({ email: email_query, userId: userId_query });
        }
        return;
      } else {
        return navigate("/");
      }
    }

    navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userId, email, navigate]);

  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: {
      number: "",
      repetitionInterval: "",
      dayOfWeek: "",
      time: null,
      timeZone: timeZones.some(
        (timeZone) => timeZone.value === dayjs.tz.guess()
      )
        ? dayjs.tz.guess()
        : timeZones[0].value,
      hours: "",
    },
    resolver: yupResolver(ReminderSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (showDayOfWeekInput) {
      setValue("dayOfWeek", "");
    } else {
      setValue("dayOfWeek", "Sunday");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDayOfWeekInput]);

  const onSubmit = async (data) => {
    try {
      // add all user properties
      const identifyObj = new amplitude.Identify();
      identifyObj.set(
        "Phone",
        data?.number !== "" && data?.number !== null ? "Yes" : "No"
      );

      const Affordability =
        data?.number !== "" && data?.number
          ? matchCountryCode(data?.number, settings?.SmsServicesAvailability)
            ? "Yes"
            : "No"
          : "NoPhoneNumber";
      identifyObj.set("Affordability", Affordability);

      let Notifications = "Email";

      if (data?.number !== "" && data?.number) {
        Notifications = "Email,SMS";
      }

      identifyObj.set("Notifications", Notifications);
      amplitude.identify(identifyObj);

      amplitude.logEvent("Reminders_Submitted", {
        Frequency: data.repetitionInterval,
        Trigger: fromTrigger ? fromTrigger : undefined,
      });

      // delete set reminder record
      await deleteDataById("/exitWithoutSetReminder", userId);

      const formattedTime = data.time;

      // prepare reminder data
      let reminderRecordPayload = {
        timeZone: data.timeZone,
        getEmails: true,
        getMessages: true,
        getMissedCheckInEmails: true,
        getMissedCheckInMessages: true,
      };

      // set reminder preferences
      if (data.repetitionInterval === "Weekly") {
        reminderRecordPayload["reminderPreferences"] = [
          { [data.dayOfWeek]: formattedTime },
        ];
      } else {
        reminderRecordPayload["reminderPreferences"] = [
          { Sunday: formattedTime },
          { Monday: formattedTime },
          { Tuesday: formattedTime },
          { Wednesday: formattedTime },
          { Thursday: formattedTime },
          { Friday: formattedTime },
          { Saturday: formattedTime },
        ];
      }

      // 1. check phone number already exists if exist then delete new created record and update existing record if email is not same
      if (data?.number !== "" && data?.number !== null) {
        let found = await findData("users", "phone", "==", data.number);
        found = found ? found[0] : undefined;
        // if record already exists then update that record and delete newly created record if email is not same
        if (found && found.email !== email) {
          // 1. update that user email because user enter new email but phone number is same
          await updateData(
            "/users",
            { email, name, emailVerified: false },
            found.id
          );

          // 2.delete new record
          await deleteDataById("/users", userId);

          // 3. send confirmation link to new email address
          apiCall("post", "/sendConfirmationMailToNewUser", {
            body: { userId: found.id },
          });

          // 4. set current record inside local storage
          setUseDetails({
            userId: found.id,
            email: email,
            name: name,
            unitPreference: found.unitPreference,
            sex: found.sex,
            dateOfBirth: found.dateOfBirth,
            phone: found.phone,
            emailVerified: false,
            phoneVerified: found.phoneVerified,
          });

          // 5. update reminder record
          await updateData("/reminder", reminderRecordPayload, found.id);
          setUseDetails({
            time: formattedTime,
            repetitionInterval: data.repetitionInterval,
            dayOfWeek:
              data.repetitionInterval === "Weekly" ? data.dayOfWeek : "",
            timeZone: data.timeZone,
          });
          return found.phoneVerified
            ? navigate("/onboarding")
            : setOpenDialog(true);
        }

        // email and phone both same then update reminder record
        if (found && found.email === email) {
          setUseDetails({
            userId: found.id,
            email: email,
            name: name,
            unitPreference: found.unitPreference,
            sex: found.sex,
            dateOfBirth: found.dateOfBirth,
            phone: found.phone,
            emailVerified: found.emailVerified,
            phoneVerified: found.phoneVerified,
          });
          await updateData("/reminder", reminderRecordPayload, found.id);
          setUseDetails({
            time: formattedTime,
            repetitionInterval: data.repetitionInterval,
            dayOfWeek:
              data.repetitionInterval === "Weekly" ? data.dayOfWeek : "",
            timeZone: data.timeZone,
          });
          return found.phoneVerified
            ? navigate("/onboarding")
            : setOpenDialog(true);
        }
      }

      // 2. update user record and add the user reminder record
      if (data?.number !== "" && data?.number !== null) {
        let updateDataPayload = {
          phone: data.number,
          phoneVerified: false,
        };
        await updateData("/users", updateDataPayload, userId);
      }

      await setData("/reminder", reminderRecordPayload, userId);
      setUseDetails({
        phone: data?.number,
        phoneVerified: false,
        time: formattedTime,
        repetitionInterval: data.repetitionInterval,
        dayOfWeek: data.repetitionInterval === "Weekly" ? data.dayOfWeek : "",
        timeZone: data.timeZone,
      });

      apiCall("post", "/sendConfirmationMailToNewUser", {
        body: { userId: userId },
      });

      return data?.number ? setOpenDialog(true) : navigate("/onboarding");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <MainContainer>
      <Stack
        sx={{
          mt: theme.margin.margin1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "pointer",
            textWrap: "nowrap",
          }}
          onClick={() => {}}
        >
          CHECK-IN
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            marginInline: "10px",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
          }}
        >
          {">"}
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
          }}
          onClick={() => {}}
        >
          CONFIRM
        </Typography>
      </Stack>

      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ my: "24px", alignItems: "center" }}
      >
        <NewRadioButton
          control={control}
          name="repetitionInterval"
          label="How Often"
          options={repetitionInterval}
          componentStyle={{ width: "100%" }}
          onChangeOption={(selectedValue) => {
            if (selectedValue === "Daily")
              showDayOfWeekInput && setShowDayOfWeekInput(false);
            else !showDayOfWeekInput && setShowDayOfWeekInput(true);
          }}
          description="For most people, weekly is best"
        />
        <Collapse
          sx={{ width: "100%", mt: "28px" }}
          in={Boolean(showDayOfWeekInput)}
        >
          <NewRadioButton
            control={control}
            name="dayOfWeek"
            label="Which day?"
            options={dayOfWeek}
            description="Pick a day without socials day before"
          />
        </Collapse>
        <Box width={"100%"} mt={"28px"}>
          <ElementLabel
            label={"What time?"}
            description={"Pick a time that’s before you eat"}
          />
          <Box
            sx={{
              mt: "6px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: { xs: "5px", sm: "18px" },
              width: "100%",
            }}
          >
            <NewTimePicker
              onChange={(newValue) => {
                setValue("time", newValue); // Update the form value
              }}
              stackStyle={{ mt: 0 }}
            />
            <SelectInput
              control={control}
              name="timeZone"
              label=""
              options={timeZones}
              removeMargin
              componentStyle={{ maxWidth: { xs: "120px", sm: "280px" } }}
            />
          </Box>
          <ErrorText error={formState?.errors?.time} />
        </Box>

        <MobileNumberInput
          control={control}
          name="number"
          label={"SMS reminder"}
          description={"As emails can be easily missed"}
          stackXs={{ mt: "28px" }}
        />

        <PrimaryButton
          isLoading={
            loading ||
            updateDataLoading ||
            findDataLoading ||
            deleteLoading ||
            apiCallLoading
          }
          type="submit"
          sx={{
            mt: "48px",
            maxWidth: "220px",
            width: { xs: "100%", md: "unset" },
          }}
        >
          Next
        </PrimaryButton>
      </Stack>

      {openDialog && (
        <OTPFormDialog
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          successDialog={true}
          navigateTo={"/onboarding"}
        />
      )}
    </MainContainer>
  );
};

export default ReminderForm;
