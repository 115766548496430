import * as yup from "yup";

export const MenMeasurementSchema = yup.object().shape({
  height: yup
    .number()
    .typeError("please enter a valid height value")
    .required("Height value is required")
    .positive("Height value must be a positive number.")
    .when("unitPreference", (unitPreference, schema) =>
      unitPreference === "Metric"
        ? schema.min(121, "Please enter height in centimeters (cm).")
        : schema.min(48, "Enter height in inches")
    ),

  weight: yup
    .number()
    .typeError("please enter a valid Weight value")
    .required("Weight value is required")
    .positive("Weight value must be positive number."),

  waist: yup
    .number()
    .typeError("please enter a valid Waist value")
    .required("Waist value is required")
    .positive("Waist value must be positive number."),

  neck: yup
    .number()
    .typeError("please enter a valid Neck value")
    .required("Neck value is required")
    .positive("Neck value must be positive number."),
});

export const WomenMeasurementSchema = yup.object().shape({
  height: yup
    .number()
    .typeError("please enter a valid height value")
    .required("Height value is required")
    .positive("Height value must be a positive number.")
    .when("unitPreference", (unitPreference, schema) =>
      unitPreference === "Metric"
        ? schema.min(121, "Please enter height in centimeters (cm).")
        : schema.min(48, "Enter height in inches")
    ),

  weight: yup
    .number()
    .typeError("please enter a valid Weight value")
    .required("Weight value is required")
    .positive("Weight value must be positive number."),

  waist: yup
    .number()
    .typeError("please enter a valid Waist value")
    .required("Waist value is required")
    .positive("Waist value must be positive number."),

  neck: yup
    .number()
    .typeError("please enter a valid Neck value")
    .required("Neck value is required")
    .positive("Neck value must be positive number."),

  hip: yup
    .number()
    .typeError("please enter a valid Hip value")
    .required("Hip value is required")
    .positive("Hip value must be positive number."),
});

export const MenMeasurementSchemaAfterLogin = yup.object().shape({
  weight: yup
    .number()
    .typeError("please enter a valid Weight value")
    .required("Weight value is required")
    .positive("Weight value must be positive number."),

  waist: yup
    .number()
    .typeError("please enter a valid Waist value")
    .required("Waist value is required")
    .positive("Waist value must be positive number."),

  neck: yup
    .number()
    .typeError("please enter a valid Neck value")
    .required("Neck value is required")
    .positive("Neck value must be positive number."),
});

export const WomenMeasurementSchemaAfterLogin = yup.object().shape({
  weight: yup
    .number()
    .typeError("please enter a valid Weight value")
    .required("Weight value is required")
    .positive("Weight value must be positive number."),

  waist: yup
    .number()
    .typeError("please enter a valid Waist value")
    .required("Waist value is required")
    .positive("Waist value must be positive number."),

  neck: yup
    .number()
    .typeError("please enter a valid Neck value")
    .required("Neck value is required")
    .positive("Neck value must be positive number."),

  hip: yup
    .number()
    .typeError("please enter a valid Hip value")
    .required("Hip value is required")
    .positive("Hip value must be positive number."),
});
