import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect, useState } from "react";
import MainContainer from "../components/global/container/MainContainer";
import { Box, Stack, Typography, alpha, useTheme } from "@mui/material";
import { useAuth } from "../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/global/button/PrimaryButton";
import { useCommon } from "../hooks/store/useCommon";
import { matchCountryCode } from "../utils/SmsSeviceChecker";

const OnBoarding = () => {
  const { userId, phone, CloseLeadStatus } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { settings, fromTrigger } = useCommon();
  const [smsServiceAvailability, setSmsServiceAvailability] = useState(
    !phone ? true : null
  );

  useEffect(() => {
    amplitude.logEvent("ThankYou_Visited", {
      Trigger: fromTrigger ? fromTrigger : undefined,
    });
    phone &&
      settings?.SmsServicesAvailability &&
      setSmsServiceAvailability(
        matchCountryCode(phone, settings?.SmsServicesAvailability)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, phone]);

  useEffect(() => {
    if (!userId) navigate("/");
  }, [userId, navigate]);

  return (
    <MainContainer>
      <Stack
        sx={{
          mt: theme.margin.margin1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.text.disabled,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
            textWrap: "nowrap",
          }}
        >
          CHECK-IN
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            marginInline: "10px",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
          }}
        >
          {">"}
        </Typography>
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
          }}
          onClick={() => {}}
        >
          CONFIRM
        </Typography>
      </Stack>
      <Typography
        sx={{
          marginTop: theme.margin.margin1,
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingMain,
          color: theme.text.primary,
          fontWeight: theme.typography.headingSecondaryBold,
        }}
      >
        {smsServiceAvailability && CloseLeadStatus !== "BBB Customer"
          ? "Amazing! 2 more things"
          : "Amazing!"}
      </Typography>
      <Box
        sx={{
          mt: theme.margin.margin1,
          padding: "14px",
          backgroundColor: "background.grey",
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamily2,
            fontSize: theme.typography.headingBasic,
            color: theme.text.basic,
            fontWeight: theme.typography.headingBasicBold,
          }}
        >
          {smsServiceAvailability && CloseLeadStatus !== "BBB Customer"
            ? "1. Check your email (including spam/folders)"
            : "Check your email (including spam/folders)"}
        </Typography>
        <Typography
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin4,
            lineHeight: "20px",
          }}
        >
          Click the confirm link in the email I just sent you to get check-in
          reminders at your chosen times.
        </Typography>
      </Box>
      {smsServiceAvailability && CloseLeadStatus !== "BBB Customer" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: theme.margin.margin1,
            padding: theme.padding.padding2,
            background: alpha(theme.palette.background.primary, 0.1),
            borderRadius: "16px",
          }}
        >
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily2,
              fontSize: theme.typography.headingBasic,
              color: theme.text.basic,
              fontWeight: theme.typography.headingBasicBold,
            }}
          >
            2. Get unstuck immediately
          </Typography>
          <Typography
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: theme.margin.margin3,
              lineHeight: "18px",
            }}
          >
            Let’s pinpoint your 1 Small Change for Maximum Results.
          </Typography>
          <Typography
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: theme.margin.margin2,
              lineHeight: "18px",
            }}
          >
            We’ll do this by understanding your:
          </Typography>

          <Box
            component={"ul"}
            sx={{
              listStylePosition: "outside",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          >
            <Typography
              component={"li"}
              sx={{
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin4,
                lineHeight: "16px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontWeight: theme.typography.headingMainBold,
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Body:
              </Typography>{" "}
              what is normal for you
            </Typography>
            <Typography
              component={"li"}
              sx={{
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin4,
                lineHeight: "16px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontWeight: theme.typography.headingMainBold,
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Emotions:
              </Typography>{" "}
              your specific triggers and relationship with food
            </Typography>
            <Typography
              component={"li"}
              sx={{
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin4,
                lineHeight: "16px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontWeight: theme.typography.headingMainBold,
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Beliefs:
              </Typography>{" "}
              what you believe about yourself and what’s real
            </Typography>
          </Box>
          <PrimaryButton
            component={"a"}
            href={
              "https://www.coachviva.com/resources/unstuck-call?utm_source=bfcalc"
            }
            target={"_blank"}
            sx={{ mt: "20px", mx: "auto", p: "24px", maxWidth: "220px" }}
            onClick={() => {
              amplitude.logEvent("ThankYou_CallClicked", {
                Trigger: fromTrigger ? fromTrigger : undefined,
              });
            }}
          >
            Book Free 15-min Call
          </PrimaryButton>
        </Box>
      )}
    </MainContainer>
  );
};

export default OnBoarding;
