import * as React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../global/button/PrimaryButton";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { InputFiledWithSelect } from "../global/form/InputFiledWithSelect";
import { useAuth } from "../../hooks/store/useAuth";
import {
  MeasurementsSourceMenAfterLogin,
  MeasurementsSourceWomenAfterLogin,
} from "../../inputConfigs/MeasurementsSource";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MenMeasurementSchemaAfterLogin,
  WomenMeasurementSchemaAfterLogin,
} from "../../validators/MeasurementSchema";
import { useForm } from "react-hook-form";
import useUpdateData from "../../hooks/useUpdateData";
import { useCommon } from "../../hooks/store/useCommon";
import { formatFirebaseTimestamp } from "../../functions/formatFirebaseTimestamp";
import {
  cmToInches,
  inchesToCm,
  kgToLb,
  lbToKg,
} from "../../functions/unitConvertor";
import OutlinedButton from "../global/button/OutlinedButton";
import useDeleteData from "../../hooks/useDeleteData";
import UpdateCheckInDateDialog from "./UpdateCheckInDateDialog";
import dayjs from "dayjs";
import BtnToast from "../global/toast/BtnToast";

export default function EditCheckInDialog({ openDialog, setOpenDialog }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { unitPreference, userId, setUseDetails, sex, height } = useAuth();
  const { updateData, loading } = useUpdateData();
  const { deleteDataById, loading: deleteLoading } = useDeleteData();
  const { updateCheckInId, updateRecordCurrentValue, totalCheckInLength } =
    useCommon();
  const [value, setValue] = React.useState(unitPreference === "Metric" ? 1 : 0);
  const [openUpdateDateDialog, setOpenUpdateDateDialog] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateData(
      "/users",
      {
        unitPreference: newValue ? "Metric" : "Imperial",
      },
      userId
    );
    setUseDetails({
      unitPreference: newValue ? "Metric" : "Imperial",
    });
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  async function updateCheckInRecord(data) {
    try {
      await updateData("checkIns", data, updateCheckInId);
      amplitude.logEvent("CheckIn_Edit");
      return handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteCheckInRecord() {
    try {
      await deleteDataById("checkIns", updateCheckInId);
      amplitude.logEvent("CheckIn_Delete");
      return handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  const {
    handleSubmit,
    control,
    reset,
    formState,
    setValue: setFormValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      sex === "Female"
        ? WomenMeasurementSchemaAfterLogin
        : MenMeasurementSchemaAfterLogin
    ),
    mode: "onchange",
  });
  const { dirtyFields } = formState;
  const { createdAt } = watch();
  const [showToast, setShowToast] = React.useState(false);
  const currentCheckInDate = formatFirebaseTimestamp(
    updateRecordCurrentValue?.createdAt?.seconds
  );
  const updateBtnDisable =
    currentCheckInDate === dayjs(createdAt).format("DD/MM/YYYY");

  //   effect for set default value of form
  React.useEffect(() => {
    reset({
      createdAt: dayjs(currentCheckInDate, "DD/MM/YYYY"),
      weight:
        unitPreference === "Imperial"
          ? updateRecordCurrentValue.weight
          : lbToKg(updateRecordCurrentValue.weight, true),
      waist:
        unitPreference === "Imperial"
          ? updateRecordCurrentValue.waist
          : inchesToCm(updateRecordCurrentValue.waist, true),
      neck:
        unitPreference === "Imperial"
          ? updateRecordCurrentValue.neck
          : inchesToCm(updateRecordCurrentValue.neck, true),
      ...(sex === "Female"
        ? {
            hip:
              unitPreference === "Imperial"
                ? updateRecordCurrentValue.hip
                : inchesToCm(updateRecordCurrentValue.hip),
          }
        : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitPreference]);

  // display error message onsubmit form if any form error exist
  React.useEffect(() => {
    if (Object.keys(formState.errors).length > 0) setShowToast(true);
  }, [formState.errors]);

  const onSubmit = (data) => {
    let imperialConvertedData = {
      weight: unitPreference === "Imperial" ? data.weight : kgToLb(data.weight),
      waist:
        unitPreference === "Imperial" ? data.waist : cmToInches(data.waist),
      neck: unitPreference === "Imperial" ? data.neck : cmToInches(data.neck),
      height,
      ...(sex === "Female"
        ? {
            hip:
              unitPreference === "Imperial" ? data.hip : cmToInches(data.hip),
          }
        : {}),
    };

    updateCheckInRecord({
      ...imperialConvertedData,
      createdAt: dayjs(data.createdAt, "DD/MM/YYYY").toDate(),
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "596px" },
              backgroundColor: "#faf9f9",
            },
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.main",
            }}
          >
            <Tabs
              sx={{
                width: "100%",
                marginTop: theme.margin.margin2,
                boxShadow: "0px 5px 4px #E9ECEF",
              }}
              value={value}
              onChange={handleChange}
              centered
            >
              <Tab
                label="Imperial"
                sx={{
                  width: "50%",
                  fontFamily: theme.typography.fontFamily2,
                  fontSize: "18px",
                  fontWeight: theme.typography.heading1Bold,
                  textTransform: "capitalize",
                }}
              />
              <Tab
                label="Metric"
                sx={{
                  width: "50%",
                  fontFamily: theme.typography.fontFamily2,
                  fontSize: "18px",
                  fontWeight: theme.typography.heading1Bold,
                  textTransform: "capitalize",
                }}
              />
            </Tabs>
          </Box>
        </Stack>
        <Box
          sx={{
            paddingInline: { xs: "12px", sm: "18px" },
          }}
        >
          <Stack
            sx={{
              mt: "24px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                ml: "5%",
                mt: "24px",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  ml: "5%",
                  fontSize: "22px",
                  fontWeight: 700,
                  textWrap: "nowrap",
                }}
              >
                {createdAt?.format("MMMM D")}
              </Typography>
              <OutlinedButton
                sx={{
                  border: "none !important",
                  display: "inline !important",
                  "&.MuiButton-root": {
                    color: "info.secondary",
                    fontSize: { xs: "14px", sm: "16px" },
                    p: 0,
                    minWidth: 0,
                  },
                }}
                onClick={() => setOpenUpdateDateDialog(true)}
              >
                Edit
              </OutlinedButton>
            </Stack>

            <Button
              onClick={handleClose}
              variant="text"
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
          </Stack>
          <Stack
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            sx={{ alignItems: "flex-end", mb: "12px" }}
          >
            {(sex === "Female"
              ? MeasurementsSourceWomenAfterLogin
              : MeasurementsSourceMenAfterLogin
            ).map((inputConfig) => (
              <InputFiledWithSelect
                key={inputConfig.name}
                name={inputConfig.name}
                label={inputConfig.label}
                icon={inputConfig.icon}
                control={control}
                description={inputConfig?.description}
                iconSx={inputConfig?.iconSx ? inputConfig?.iconSx : {}}
                guestCheckIn={false}
              />
            ))}

            <Stack
              sx={{
                mt: "48px",
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "flex-end",
                gap: "14px",
              }}
            >
              <BtnToast
                text={"Please enter valid values."}
                visibility={showToast}
                setVisibility={setShowToast}
                type="error"
              />
              <PrimaryButton
                disabled={
                  Object.keys(dirtyFields).length === 0 && updateBtnDisable
                }
                isLoading={loading}
                type="submit"
                sx={{
                  width: "100%",
                  p: "18px !important",
                  fontSize: "18px !important",
                  alignSelf: "center",
                }}
              >
                Update
              </PrimaryButton>
              {totalCheckInLength > 1 && (
                <OutlinedButton
                  isLoading={deleteLoading}
                  onClick={deleteCheckInRecord}
                  sx={{
                    width: { xs: "200px", sm: "220px" },
                    p: "16px !important",
                    fontSize: "18px !important",
                    alignSelf: "center",
                  }}
                >
                  Delete
                </OutlinedButton>
              )}
            </Stack>
          </Stack>
        </Box>
        {openUpdateDateDialog && (
          <UpdateCheckInDateDialog
            openDialog={openUpdateDateDialog}
            setOpenDialog={setOpenUpdateDateDialog}
            currentCheckInDate={createdAt}
            updateCheckInDate={setFormValue}
          />
        )}
      </Dialog>
    </React.Fragment>
  );
}
