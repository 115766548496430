export function bodyStatusChecker(gender, goal, bodyFatPercentage) {
  if (gender === "Male") {
    if (bodyFatPercentage < 8) {
      return { status: "Underfat", index: 0 };
    } else if (bodyFatPercentage >= 8 && bodyFatPercentage <= 14.9) {
      return { status: "Healthy", index: 1 };
    } else if (bodyFatPercentage >= 15 && bodyFatPercentage <= 20) {
      return { status: "Acceptable", index: 2 };
    } else {
      return { status: "Overfat", index: 3 };
    }
  } else {
    if (bodyFatPercentage < 18) {
      return { status: "Underfat", index: 0 };
    } else if (bodyFatPercentage >= 18 && bodyFatPercentage <= 24.9) {
      return { status: "Healthy", index: 1 };
    } else if (bodyFatPercentage >= 25 && bodyFatPercentage <= 30) {
      return { status: "Acceptable", index: 2 };
    } else {
      return { status: "Overfat", index: 3 };
    }
  }
}
