import * as amplitude from "@amplitude/analytics-browser";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MobileNumberInput from "../../global/form/MobileNumberInput";
import PrimaryButton from "../../global/button/PrimaryButton";
import * as yup from "yup";
import { useAuth } from "../../../hooks/store/useAuth";
import PhoneEmailDialog from "../dialog/PhoneEmailDialog";
import useFindData from "../../../hooks/useFindData";
import { toast } from "react-toastify";
import MainContainer from "../../global/container/MainContainer";
import NewInputField from "../../global/form/NewInputField";

const UpdateEmailPhone = () => {
  useEffect(() => {
    amplitude.logEvent("Profile_Visited");
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    title: "Update your Contact Information",
    description: "",
  });

  const { phone, email } = useAuth();
  const [formData, setFormData] = useState({});
  const { findData, loading: loadingFind } = useFindData();
  const { text } = useTheme();

  const schema = yup.object().shape(
    {
      email: yup
        .string()
        .email("Please enter a valid email address")
        .required("Email is required"),
      number: yup
        .string()
        .nullable()
        .when("number", {
          is: (value) => value && value.length > 0,
          then: yup
            .string()
            .phone("Invalid phone number")
            .typeError("Phone number must be a valid number"),
          otherwise: yup.string(),
        }),
    },
    [["number", "number"]]
  );

  const { handleSubmit, control, formState, watch } = useForm({
    defaultValues: { number: phone, email: email },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { email: inputEmail, number: inputNumber } = watch();
  const isFieldUpdated =
    email !== inputEmail || (inputNumber !== "" && phone !== inputNumber);

  // function for call on submit form
  const onSubmit = async (data) => {
    setFormData(data);
    let description = "Are you sure you want to update your ";
    if (data.email !== email) {
      let checkEmail = await findData("/users", "email", "==", data.email);
      if (checkEmail)
        return toast(
          "Sorry, this email is already associated with an existing account. Please use a different email.",
          {
            type: "error",
          }
        );
      description += `email to ${data.email}`;
    }
    if (data.number !== phone && data.number !== "") {
      let checkPhone = await findData("/users", "phone", "==", data.number);
      if (checkPhone)
        return toast("This phone number is already in use.", {
          type: "error",
        });
      description += `${data.email !== email ? " and " : ""}`;
      description += `phone to ${data.number}`;
    }

    // prevent unnecessary form submission
    if (data.email === email && data.number === phone) {
      return toast("Everything is same as before.", { type: "warning" });
    }

    setDialogInfo({
      title: dialogInfo.title,
      description: description,
    });
    return setOpenDialog(true);
  };

  return (
    <MainContainer>
      <Stack component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            mt: "6px",
            color: text.primary,
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          My Profile
        </Typography>

        <NewInputField
          name={"email"}
          type={"email"}
          label={"Email"}
          placeholder={"Enter your Email"}
          control={control}
          errorMsg={true}
          stackXs={{ mt: "24px", maxWidth: "78%" }}
        />
        <MobileNumberInput
          label={"Phone"}
          stackXs={{ mt: "24px" }}
          control={control}
          name="number"
          sx={{ mt: "0px", maxWidth: "80%" }}
        />

        <Stack
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "flex-end",
            justifyContent: { md: "flex-end" },
            gap: "24px",
            mt: "48px",
          }}
        >
          <PrimaryButton
            disabled={!isFieldUpdated && formState.isValid}
            isLoading={loadingFind}
            type="submit"
            sx={{
              mt: "48px",
              width: { xs: "100%", md: "unset" },
              alignSelf: "center",
            }}
          >
            Update
          </PrimaryButton>
        </Stack>

        <PhoneEmailDialog
          title={dialogInfo.title}
          description={dialogInfo.description}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formData={formData}
        />
      </Stack>
    </MainContainer>
  );
};

export default UpdateEmailPhone;
