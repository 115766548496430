import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import FileProgressBar from "./FileProgressBar";

export default function FileUploadDialog({
  openDialog,
  FileUploadProgressInfo = [],
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "596px" },
              backgroundColor: "#faf9f9",
            },
          },
        }}
        open={openDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack sx={{ padding: "12px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: theme.typography.fontFamily2,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            File Uploading
          </Typography>

          {FileUploadProgressInfo.map((file) => (
            <FileProgressBar
              key={file.fileName}
              fileName={file.fileName}
              progress={file.progress}
            />
          ))}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
