import { useState } from "react";
import axios from "axios";

const useApiCall = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [response, setResponse] = useState(null);

  const apiCall = async (method, url, { query, body } = {}) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      // Axios configuration
      const config = {
        method,
        url: process.env.REACT_APP_API_URL + url,
        params: query,
        data: body,
      };

      // Make the API call
      let apiResponse = await axios(config);
      setResponse(apiResponse.data);
      return apiResponse.data;
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, errorMessage, response, apiCall };
};

export default useApiCall;
