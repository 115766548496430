import React, { useEffect } from "react";
import useFindData from "../hooks/useFindData";
import { useParams } from "react-router-dom";
import MainContainer from "../components/global/container/MainContainer";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import BrandIcon from "../icons/BrandIcon";

const ShortUrl = () => {
  const { findDataById } = useFindData();
  const { token } = useParams();
  const { text } = useTheme();

  useEffect(() => {
    async function getRedirectUrl() {
      try {
        if (!token) {
          console.error("Token not found in URL parameters.");
          return;
        }

        const res = await findDataById("/shortUrl", token);
        if (res && res.originalUrl) {
          window.location.href = res.originalUrl; // Redirect to the fetched redirectUrl
        } else {
          window.location.href = `https://${process.env.REACT_APP_DOMAIN}/login`; // Fallback to the main domain if redirectUrl is not found
        }
      } catch (error) {
        window.location.href = `https://${process.env.REACT_APP_DOMAIN}/login`; // Fallback in case of an error
      }
    }

    getRedirectUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer showNavbar={false}>
      <Stack
        sx={{
          gap: "12px",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "100",
          height: "100dvh",
        }}
      >
        <BrandIcon
          sx={{
            zIndex: 99,
            cursor: "pointer",
            width: { xs: "38px", sm: "42px" },
            height: { xs: "38px", sm: "42px" },
          }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            cursor: "pointer",
            textDecoration: "none",
            color: text.basic,
          }}
        >
          Coach Viva
        </Typography>
        <CircularProgress size={20} color="inherit" />
      </Stack>
    </MainContainer>
  );
};

export default ShortUrl;
