// Function to convert kilograms to pounds
export function kgToLb(weightInKg) {
  const lbsPerKg = 2.20462;
  return Number(parseFloat(weightInKg * lbsPerKg).toFixed(2));
}

// Function to convert feet to inches
export function ftToInches(lengthInFeet) {
  const inchesPerFoot = 12;
  return parseFloat(lengthInFeet * inchesPerFoot).toFixed(2);
}

// Function to convert centimeters to inches
export function cmToInches(lengthInCm) {
  const inchesPerCm = 0.393701;
  return Number(parseFloat(lengthInCm * inchesPerCm).toFixed(2));
}

export function inchesToCm(lengthInInches, roundFigure = false) {
  const cmPerInch = 2.54;
  return roundFigure
    ? Math.round(lengthInInches * cmPerInch)
    : parseFloat(lengthInInches * cmPerInch).toFixed(2);
}

export function lbToKg(weightInLb, roundFigure = false) {
  if (weightInLb === 0) return 0;
  const kgPerLb = 0.453592;
  return roundFigure
    ? Math.round(weightInLb * kgPerLb)
    : parseFloat(weightInLb * kgPerLb).toFixed(2);
}
