import { ClickAwayListener } from "@mui/base";
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import WarningIcon from "../../../icons/form-icon/WarningIcon";

const ErrorToolTip = ({ errorText }) => {
  const { boxShadow, text } = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          tabIndex={-1}
          componentsProps={{
            tooltip: {
              sx: {
                p: 0,
                boxShadow: boxShadow.basic,
                backgroundColor: "background.main",
                maxWidth: "unset",
              },
            },
          }}
          PopperProps={{
            disablePortal: true,
            sx: {
              "& .MuiTooltip-arrow": {
                fontSize: "16px",
              },
              "& .MuiTooltip-arrow::before": {
                boxShadow: boxShadow.basic,
                backgroundColor: "background.main",
              },
            },
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Typography
              sx={{ fontSize: "14px", p: "6px", color: text.deepPrimary }}
            >
              {errorText}
            </Typography>
          }
          sx={{
            "& .MuiTooltip-tooltip": {
              backgroundColor: "background.main !important",
            },
          }}
        >
          <IconButton sx={{ p: 0 }} onClick={handleTooltipOpen}>
            <WarningIcon
              sx={{
                width: { xs: "16px", sm: "20px" },
                height: { xs: "16px", sm: "20px" },
              }}
              color={text.deepPrimary}
            />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ErrorToolTip;
