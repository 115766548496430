import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NewDatePicker from "../global/form/NewDatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import PrimaryButton from "../global/button/PrimaryButton";
import { checkInDateSchema } from "../../validators/checkInDateSchema";
import { yupResolver } from "@hookform/resolvers/yup";

export default function UpdateCheckInDateDialog({
  openDialog,
  setOpenDialog,
  currentCheckInDate,
  updateCheckInDate,
}) {
  const theme = useTheme();
  const { handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      checkInDate: currentCheckInDate,
    },
    resolver: yupResolver(checkInDateSchema),
    mode: "onChange",
  });

  function submitForm(data) {
    try {
      updateCheckInDate("createdAt", dayjs(data.checkInDate));
      return setOpenDialog(false);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              padding: { xs: "12px", sm: "18px" },
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack
          sx={{
            position: "relative",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mt: "24px",
              fontFamily: theme.typography.fontFamily2,
              fontSize: theme.typography.headingBasic,
              color: theme.text.basic,
              fontWeight: theme.typography.headingBasicBold,
            }}
          >
            Update CheckIn date
          </Typography>

          <Stack
            component={"form"}
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
            onSubmit={handleSubmit(submitForm)}
          >
            <NewDatePicker
              value={currentCheckInDate}
              onChange={(newValue) => {
                setValue("checkInDate", newValue); // Update the form value
              }}
              error={formState?.errors?.checkInDate}
              label={"Choose date"}
            />

            <PrimaryButton type={"submit"}>Submit</PrimaryButton>
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
