import { Typography, useTheme } from "@mui/material";
import React from "react";

const ErrorText = ({ error, errorSx = {} }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        mt: "12px",
        color: "error.main",
        fontSize: theme.typography.heading1,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22px",
        ...errorSx,
      }}
    >
      {error?.message}
    </Typography>
  );
};

export default ErrorText;
