import React, { useState } from "react";
import TapeReminderForm from "../components/tape-reminder/TapeReminderForm";
import TapeReminderFormSubmit from "../components/tape-reminder/TapeReminderFormSubmit";

const TapeReminder = () => {
  let [openForm, setOpenFrom] = useState("form"); // 1.form 2.submitted
  let [phone, setPhone] = useState(null);
  return (
    <>
      {openForm === "form" ? (
        <TapeReminderForm
          setOpenFrom={() => setOpenFrom("submitted")}
          setPhone={setPhone}
        />
      ) : (
        <TapeReminderFormSubmit phone={phone} />
      )}
    </>
  );
};

export default TapeReminder;
