import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const ElementLabel = ({
  label = false,
  description = false,
  labelSx = {},
  descriptionSx = {},
}) => {
  const theme = useTheme();
  return (
    <Stack>
      {label && (
        <Typography
          sx={{
            fontSize: theme.typography.heading1,
            fontWeight: theme.typography.heading1Bold,
            ...labelSx,
          }}
        >
          {label}
        </Typography>
      )}
      {description && (
        <Typography
          sx={{
            fontSize: theme.typography.heading1,
            ...descriptionSx,
          }}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default ElementLabel;
