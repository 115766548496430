import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PrimaryButton from "../global/button/PrimaryButton";

export default function SuggestionDialog({ openDialog, setOpenDialog }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "800px" },
              padding: { xs: "16px", sm: "20px", md: "48px" },
              backgroundColor: "background.secondary",
              position: "relative",
            },
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          sx={{
            p: 0,
            position: "fixed",
            alignSelf: "flex-end",
            zIndex: "100",
          }}
          onClick={handleClose}
        >
          <CloseRoundedIcon
            sx={{ fontSize: "34px", color: theme.text.basic }}
          />
        </IconButton>

        <Stack>
          <Typography
            sx={{
              color: theme.text.basic,
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "36px",
            }}
          >
            Suggestion
          </Typography>

          <Typography
            sx={{
              mt: { xs: "24px", md: "48px" },
              color: theme.text.basic,
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "27px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque rhoncus vulputate lacus at sollicitudin. Duis
            scelerisque lacus urna, vehicula condimentum lacus ullamcorper a.
            Donec dapibus sit amet nunc ut mollis.{" "}
          </Typography>

          <PrimaryButton
            sx={{ mt: "48px", padding: "24px !important" }}
            component={"a"}
            href={
              "https://www.coachviva.com/resources/unstuck-call?utm_source=bfcalc"
            }
            target={"_blank"}
          >
            Book Free Call
          </PrimaryButton>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
