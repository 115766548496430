import * as amplitude from "@amplitude/analytics-browser";
import { Stack, alpha, useTheme } from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import useFindData from "../../../hooks/useFindData";
import useSetData from "../../../hooks/useSetData";
import { useAuth } from "../../../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { generateUniqueId } from "../../../functions/generateUniqueId";
import { InputFiled } from "./InputFiled";
import { useFirstCheckIn } from "../../../hooks/store/useFirstCheckIn";
import { useGuest } from "../../../hooks/store/useGuest";
import useUpdateData from "../../../hooks/useUpdateData";
import { useCommon } from "../../../hooks/store/useCommon";
import useApiCall from "../../../hooks/useApiCall";

const MailForm = ({
  inputSx,
  buttonSx,
  content,
  btnText = "I want FREE access",
}) => {
  const theme = useTheme();
  const { dateOfBirth, gender: sex, unitPreference } = useFirstCheckIn();
  const { loading, setData } = useSetData();
  const { loading: findDataLoading, findData } = useFindData();
  const { apiCall, loading: apiCallLoading } = useApiCall();
  const { updateData } = useUpdateData();
  const { setUseDetails, referrer } = useAuth();
  const { guestCheckIns } = useGuest();
  const { fromTrigger } = useCommon();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    name: yup
      .string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { email: "", name: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      amplitude.logEvent("Result_Track", {
        Trigger: fromTrigger ? fromTrigger : undefined,
      });

      // 1. check user already exists
      let found = await findData("/users", "email", "==", data.email);
      if (found) {
        found = found[0];
        const leadStatus = await apiCall("post", "/checkLeadStatus", {
          body: { email: data.email },
        });
        setUseDetails({
          userId: found.id,
          email: found.email,
          name: found.name,
          unitPreference: found.unitPreference,
          sex: found.sex,
          dateOfBirth: found.dateOfBirth,
          phone: found?.phone ? found?.phone : undefined,
          phoneVerified: found?.phoneVerified ? found?.phoneVerified : false,
          CloseLeadStatus: leadStatus?.status,
        });
        reset();
        return navigate("/set-reminder");
      } else {
        // create new user account
        const userId = generateUniqueId();
        await setData(
          "/users",
          {
            email: String(data.email).toLowerCase(),
            name: data.name,
            emailVerified: false,
            dateOfBirth,
            sex,
            unitPreference,
            referrer: referrer,
          },
          userId
        );

        const leadStatus = await apiCall("post", "/checkLeadStatus", {
          body: { email: data.email },
        });

        setUseDetails({
          userId,
          email: String(data.email).toLowerCase(),
          name: data.name,
          sex,
          unitPreference,
          dateOfBirth,
          referrer: undefined,
          CloseLeadStatus: leadStatus?.status,
        });
        // convert last guest checkIn to normal checkIn
        await updateData(
          "/checkIns",
          { userId: userId },
          guestCheckIns[guestCheckIns.length - 1]
        );
        reset();
        return navigate("/set-reminder");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: "12px",
        background: alpha(theme.palette.background.primary, 0.1),
        flexDirection: { xs: "column", md: "row" },
        borderRadius: "6px",
        padding: { xs: "14px", sm: "24px" },
      }}
    >
      <InputFiled
        name={"name"}
        type={"name"}
        label={"Your name: "}
        control={control}
        errorToolTip={false}
        errorMsg={true}
        errorSx={{ mt: "6px", textAlign: "center" }}
      />
      <InputFiled
        name={"email"}
        type={"email"}
        label={"Your email: "}
        control={control}
        errorToolTip={false}
        errorMsg={true}
        componentStyle={{ marginTop: theme.margin.margin1 }}
        errorSx={{ mt: "6px", textAlign: "center" }}
      />
      <PrimaryButton
        type="submit"
        isLoading={loading || findDataLoading || apiCallLoading}
        sx={{
          marginTop: "24px",
          width: { xs: "100%", md: "unset" },
          maxWidth: "320px !important",
          alignSelf: "center",
        }}
      >
        {btnText}
      </PrimaryButton>
    </Stack>
  );
};

export default MailForm;
