import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect } from "react";
import MainContainer from "../components/global/container/MainContainer";
import { Box, Stack } from "@mui/material";
import LineChart from "../components/line-chart/LineChart";
import CheckInTable from "../components/my-progress/CheckInTable";

const MyProgress = () => {
  useEffect(() => {
    amplitude.logEvent("Progress_Visit");
  }, []);
  return (
    <MainContainer>
      {/* top container details */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: { xs: "wrap", xxl: "nowrap" },
          gap: "24px",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "background.main",
            borderRadius: "6px",
          }}
        >
          <LineChart />
        </Stack>
      </Box>

      <CheckInTable />
    </MainContainer>
  );
};

export default MyProgress;
