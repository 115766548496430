import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import React from "react";

const FileProgressBar = ({ fileName, progress = 0 }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        mt: "10px",
        padding: "12px 14px",
        borderRadius: "12px",
        background: theme.palette.background.white,
      }}
    >
      <Typography sx={{ fontSize: "14px" }}>{fileName}</Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ mt: "4px", borderRadius: "12px" }}
      />
    </Box>
  );
};

export default FileProgressBar;
