import React from "react";
import DeleteAccount from "./DeleteAccount";
import ExportData from "./ExportData";

const Privacy = () => {
  return (
    <>
      <ExportData />
      <DeleteAccount />
    </>
  );
};

export default Privacy;
