import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
  Switch,
} from "@mui/material";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import ToolTip from "../../result/ToolTip";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 56,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "green",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
  },
  "&  .Mui-disabled": {
    opacity: 0.5,
  },
  "& .Mui-disabled .MuiSwitch-thumb": {
    backgroundColor: "#FFFFFF",
  },
}));

export const CheckBox = ({
  name,
  control,
  setValue,
  label,
  options,
  defaultValue,
}) => {
  const [selectedItems, setSelectedItems] = useState(defaultValue || []);
  const { text } = useTheme();
  const theme = useTheme();

  const handleSelect = (value) => {
    const currentIndex = selectedItems.indexOf(value);
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(value);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    setValue(name, selectedItems);
  }, [name, selectedItems, setValue]);

  return (
    <Box sx={{ mt: "38px" }}>
      <Typography
        sx={{
          color: text.basic,
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "27px",
        }}
      >
        {label}
      </Typography>
      <FormControl size={"small"} variant={"outlined"}>
        <Stack
          sx={{
            width: "100%",
          }}
        >
          {options.map((option) => (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center" }}
              key={option.value}
            >
              <FormControlLabel
                onClick={option?.onClick}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: theme.typography.heading1,
                    fontWeight: theme.typography.heading1Bold,
                  },
                  "&.MuiFormControlLabel-root": {
                    marginRight: "6px",
                  },
                }}
                control={
                  <Controller
                    name={name}
                    render={({ field }) => (
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                        disabled={option?.disabled || false}
                      />
                    )}
                    control={control}
                  />
                }
                label={option.label}
              />
              {option?.infoText && <ToolTip>{option?.infoText}</ToolTip>}
            </Stack>
          ))}
        </Stack>
      </FormControl>
    </Box>
  );
};
