import { useState } from "react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase";

const useUpdateData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [response, setResponse] = useState(null);

  const updateData = async (path, data, uid) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      // Update data in Firestore
      let response = await updateDoc(doc(db, path, uid), {
        ...data,
        updatedAt: serverTimestamp(),
      });
      setResponse("Data updated successfully!" + response);
      return response;
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, errorMessage, response, updateData };
};

export default useUpdateData;
