import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 481,
      // md: 884,
      // lg: 1048,
      // xl: 1280,
      // xxl: 1420,
    },
  },

  palette: {
    background: {
      primary: "#FA6902",
      deepPrimary: "#FA6902",
      secondary: "#F3F4F6",
      deepSecondary: "#E9ECEF",
      main: "#faf9f9",
      grey: "#ebebeb",
      white: "#ffffff",
    },

    primary: {
      main: "#FA6902",
    },
    success: {
      main: "#00780C",
      secondary: "#dffad4",
      dark: "#00bf63",
      light: "#5bf1cd",
    },
    info: {
      main: "#2196F3",
      secondary: "#0593fa",
    },
    warning: {
      main: "#997500",
    },
    error: {
      main: "#BD2628",
      secondary: "#ffd5d5",
      text: "#ff3131",
    },

    table: {
      headBackground: "#E9ECEF",
      border: "#E9ECEF",
      label: "#495057",
    },

    input: {
      secondary: "#E9ECEF",
      submitButton: "#000000",
      border: "#cacaca",
    },
  },

  border: {
    primary: "#FA6902",
  },

  typography: {
    fontFamily: "'Quicksand', sans-serif",
    fontFamily2: "'Fredoka', sans-serif",
    headingMain: { xs: "22px", sm: "26px" },
    headingMainBold: 700,
    headingSecondary: { xs: "20px", sm: "24px" },
    headingSecondaryBold: 700,
    headingBasic: { xs: "18px", sm: "20px" },
    headingBasicBold: 600,
    heading1: { xs: "14px", sm: "18px" },
    heading1Bold: 600,
    heading2: { xs: "12px", sm: "14px" },
    heading2Bold: 500,
  },

  margin: {
    margin1: { xs: "18px", sm: "20px" },
    margin2: { xs: "12px", sm: "14px" },
    margin3: { xs: "8px", sm: "10px" },
    margin4: { xs: "4px", sm: "6px" },
  },
  padding: {
    padding1: { xs: "18px", sm: "20px" },
    padding2: { xs: "12px", sm: "14px" },
  },

  text: {
    primary: "#FA6902",
    deepPrimary: "#953E00",
    secondary: "#212529",
    main: "#FFFFFF",
    basic: "#000000",
    info: "#6C757D",
    disabled: "#a9a9a9",
    grey: "#545454",
    deepSuccess: "#00A884",
  },

  button: {
    // primary button color schema
    primary: {
      color: "#FA6902",
      textPrimary: "#FFFFFF",

      disabled: "#ADB5BD",
      textDisabled: "#FFFFFF",

      hover: "#FFA642",
      clicked: "#D67200",
    },

    // outlined button color schema
    outlined: {
      color: "#FA6902",
      textOutlined: "#FA6902",

      disabled: "#ADB5BD",
      textDisabled: "#ADB5BD",

      hover: "#FA6902",
      clicked: "#FA6902",
    },
  },

  boxShadow: {
    basic: "0px 0px 4px #E9ECEF",
  },
});
