import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";
import { useLocation, useNavigate } from "react-router-dom";

const Separator = () => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        color: theme.text.disabled,
        fontSize: theme.typography.heading2,
        fontWeight: theme.typography.heading1Bold,
      }}
    >
      {">"}
    </Typography>
  );
};

const Breadcrumb = ({ hideFinalStep = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { goal, height, gender, hip } = useFirstCheckIn();
  return (
    <Stack
      sx={{
        position: "sticky",
        top: { xs: "72px", sm: "88px" },
        zIndex: 99,
        backgroundColor: "background.main",
        mt: theme.margin.margin3,
        paddingBottom: "12px",
        width: "calc(100% + 48px)",
        ml: "-24px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          paddingInline: { xs: "18px", sm: 0 },
          flexDirection: "row",
          justifyContent: { xs: "space-between", sm: "center" },
          alignItems: "center",
          gap: { sm: "24px" },
          maxWidth: { xs: "300px", sm: "unset" },
          mx: "auto",
        }}
      >
        <Typography
          sx={{
            width: { xs: "28px", sm: "35px" },
            height: { xs: "28px", sm: "35px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            color:
              location.pathname === "/" ? theme.text.main : theme.text.basic,
            backgroundColor:
              location.pathname === "/"
                ? theme.text.primary
                : "background.grey",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "pointer",
          }}
          onClick={() => {
            goal !== "" && navigate("/");
          }}
        >
          1
        </Typography>
        <Separator />
        <Typography
          sx={{
            width: { xs: "28px", sm: "35px" },
            height: { xs: "28px", sm: "35px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            color:
              location.pathname === "/measurement"
                ? theme.text.main
                : theme.text.basic,
            backgroundColor:
              location.pathname === "/measurement"
                ? theme.text.primary
                : "background.grey",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: goal !== "" ? "pointer" : "no-drop",
          }}
          onClick={() => {
            goal !== "" && navigate("/measurement");
          }}
        >
          2
        </Typography>
        <Separator />
        <Typography
          sx={{
            width: { xs: "28px", sm: "35px" },
            height: { xs: "28px", sm: "35px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            color:
              location.pathname === "/result"
                ? theme.text.main
                : theme.text.basic,
            backgroundColor:
              location.pathname === "/result"
                ? theme.text.primary
                : "background.grey",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor:
              gender === "Male"
                ? height !== ""
                  ? "pointer"
                  : "no-drop"
                : hip !== ""
                ? "pointer"
                : "no-drop",
          }}
          onClick={() => {
            if (gender === "Male") {
              height !== "" && navigate("/result");
            } else {
              hip !== "" && navigate("/result");
            }
          }}
        >
          3
        </Typography>
        {!hideFinalStep && (
          <>
            <Separator />
            <Typography
              sx={{
                width: { xs: "28px", sm: "35px" },
                height: { xs: "28px", sm: "35px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                color:
                  location.pathname === "/final-step"
                    ? theme.text.main
                    : theme.text.basic,
                backgroundColor:
                  location.pathname === "/final-step"
                    ? theme.text.primary
                    : "background.grey",
                fontSize: theme.typography.heading2,
                fontWeight: theme.typography.heading1Bold,
                cursor:
                  gender === "Male"
                    ? height !== ""
                      ? "pointer"
                      : "no-drop"
                    : hip !== ""
                    ? "pointer"
                    : "no-drop",
              }}
              onClick={() => {
                if (gender === "Male") {
                  height !== "" && navigate("/final-step");
                } else {
                  hip !== "" && navigate("/final-step");
                }
              }}
            >
              4
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Breadcrumb;
