import { Collapse, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
const BtnToast = ({
  text = "",
  visibility = false,
  setVisibility,
  type = "success",
  sx = {},
  stackSx = {},
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(visibility);

  useEffect(() => {
    setShow(visibility);

    // Set a timeout to hide the toast after 4 seconds
    if (visibility) {
      const timeoutId = setTimeout(() => {
        setShow(false);
        setVisibility(false);
      }, 5000);

      // Clear the timeout when the component unmounts or when visibility changes
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  return (
    <Collapse in={show} sx={{ width: "100%", ...sx }}>
      <Stack
        sx={{
          mt: "6px",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "2px",
          ...stackSx,
        }}
      >
        {type === "success" ? (
          <CheckCircleIcon
            style={{
              color: theme.palette.success.dark,
            }}
          />
        ) : (
          <CancelRoundedIcon
            style={{
              color: theme.palette.error.main,
            }}
          />
        )}

        <Typography
          component={"span"}
          sx={{
            color: type === "success" ? "success.dark" : "error.main",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "unset",
            maxWidth: { xs: "200px", sm: "unset" },
            textAlign: "center",
            wordBreak: "break-all",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Stack>
    </Collapse>
  );
};

export default BtnToast;
